import { useAuth0 } from '@auth0/auth0-react'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, Stack } from '@chakra-ui/layout'
import { Button, HStack, Image, VStack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router'
import { NavLink, useNavigate } from 'react-router-dom'
import ModalLegenda from '../components/CalendarioFuoriServizio/ModalLegenda'
import { MobileMenuButton } from '../components/ChakraUIProLayout/MobileMenuButton'
import { NavSectionTitle } from '../components/ChakraUIProLayout/NavSectionTitle'
import { ScrollArea } from '../components/ChakraUIProLayout/ScrollArea'
import { SidebarLink } from '../components/ChakraUIProLayout/SidebarLink'
import { UserInfo } from '../components/ChakraUIProLayout/UserInfo'
import { useMobileMenuState } from '../hooks/useMobileMenuState'
import { routes } from '../routes'

// import { ErrorBoundary } from 'react-error-boundary'
// import { SearchInput } from '../components/ChakraUIProLayout/SearchInput'

const Dashboard = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const { isOpen, toggle } = useMobileMenuState()
  const navigate = useNavigate()

  const bgColor = useColorModeValue('white', 'gray.700')
  const wrapperBgColor = useColorModeValue('gray.700', 'gray.900')

  const [modalLegendaOpen, setModalLegendaOpen] = useState(false)

  // const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  //   console.log(error, info)
  // }

  // const ErrorFallbackRoute = ({ error }: { error: Error }) => {
  //   return (
  //     <Heading mb="6" fontSize="2xl" textColor={'red.600'} fontWeight="semibold">
  //       Errore nella pagina
  //     </Heading>
  //   )
  // }

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/lavori-elettrici')
    }
  }, [isAuthenticated, navigate])

  if (isLoading) {
    return <div>loading</div>
  }
  if (!isAuthenticated) {
    console.log('NON AUTENTICATO')
    loginWithRedirect()
  }

  return isAuthenticated ? (
    <>
      <Flex height="100vh" bg={wrapperBgColor} sx={{ '--sidebar-width': '15rem' }}>
        <Box
          as="nav"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flex="1"
          height="100%"
          width="var(--sidebar-width)"
          left="0"
          paddingLeft="6"
          paddingBottom="6"
          paddingRight={{ base: '6', xl: '0' }}
          color="gray.200"
          position="fixed"
        >
          <Box fontSize="sm" lineHeight="tall">
            <Image src="/betoncablo.png" alt="BetonCablo" />

            <ScrollArea pt="5" pb="6">
              <Stack pb="6">
                <NavSectionTitle>Programmazione lavori</NavSectionTitle>
                {routes.map((route) => (
                  <NavLink
                    key={route.path}
                    to={route.path}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            backgroundColor: '#2c5282',
                            borderRadius: '0.375rem',
                          }
                        : {}
                    }
                  >
                    <SidebarLink marginInlineEnd="0">{route.sidebar}</SidebarLink>
                  </NavLink>
                ))}
              </Stack>
            </ScrollArea>
          </Box>
          <Box fontSize="sm" lineHeight="tall">
            <Box
              p={3}
              transition="background 0.1s"
              rounded="xl"
              color="gray.400"
              _hover={{
                bg: 'whiteAlpha.200',
                color: 'gray.300',
              }}
              display="block"
              as="a"
              href="https://www.notion.so/Guida-all-uso-software-acf4daefb2824f6da6c8871edaa68cbb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HStack>
                <QuestionOutlineIcon w={4} h={4} mx={2} />
                <Box>Aiuto</Box>
              </HStack>
            </Box>
            <Box
              className="separator"
              borderTop="1px solid"
              borderColor="gray.600"
              mt="0.5rem"
              pb="0.5rem"
              mx="0.25rem"
            />
            <UserInfo name={user?.nickname} email={user?.email} image={user?.picture} />
          </Box>
        </Box>

        <Box
          flex="1"
          p={{ base: '0', xl: '6' }}
          marginStart={{ xl: 'var(--sidebar-width)' }}
          position="relative"
          left={isOpen ? 'var(--sidebar-width)' : '0'}
          transition="left 0.2s"
          maxW={{
            base: isOpen ? 'calc(100vw - var(--sidebar-width))' : 'full',
            xl: 'calc(100vw - var(--sidebar-width))',
          }}
          transformOrigin="left"
        >
          <VStack
            bg={bgColor}
            height="100%"
            width="100%"
            alignItems="flex-start"
            rounded={{ xl: 'lg' }}
            px="10"
            py="8"
            overflow="hidden"
          >
            <VStack alignItems="flex-start" h="full" maxW="full" w="full">
              <HStack justifyContent="space-between" alignItems="center" w="full">
                <Box>
                  <Routes>
                    {routes.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        element={
                          <Flex alignItems="center" minH="8">
                            <MobileMenuButton onClick={toggle} isOpen={isOpen} />
                            <Heading mb="6" fontSize="24px" fontWeight="semibold">
                              {route.sidebar}
                            </Heading>
                          </Flex>
                        }
                      />
                    ))}
                  </Routes>
                </Box>
                <Box pr="7">
                  <Button
                    variant="otuline"
                    onClick={() => setModalLegendaOpen(true)}
                    fontWeight="normal"
                  >
                    <Box as="p" borderBottom="1px dashed" borderColor="gray.400" color="gray.500">
                      Legenda
                    </Box>
                  </Button>
                </Box>
              </HStack>

              <Box w="full" overflow="auto" flex="1">
                <Routes>
                  {routes.map((route) => (
                    // <ErrorBoundary
                    //   key={route.path}
                    //   FallbackComponent={ErrorFallbackRoute}
                    //   onError={myErrorHandler}
                    // >
                    <Route key={route.path} path={route.path} element={<route.main />} />
                    // </ErrorBoundary>
                  ))}
                </Routes>
              </Box>
            </VStack>
          </VStack>
        </Box>
      </Flex>
      <ModalLegenda modalLegendaOpen={modalLegendaOpen} setModalLegendaOpen={setModalLegendaOpen} />
    </>
  ) : (
    <div>Non autenticato</div>
  )
}

export default Dashboard