import React from 'react'
import { Box } from '@chakra-ui/react'
import { Tipologie } from '../../types'

interface ITipologiaAttivita {
  tipologia: Tipologie
  isPropedeutico: boolean
}

const TipologiaAvatar = ({
  string,
  color,
  isPropedeutico,
}: {
  string: string
  color?: string
  isPropedeutico?: boolean
}) => (
  <Box
    flexShrink={0}
    border={isPropedeutico ? '2px' : '1px'}
    borderStyle={isPropedeutico ? 'dashed' : 'solid'}
    borderRadius="full"
    w="26px"
    h="26px"
    display="flex"
    justifyContent="center"
    alignItems="center"
    borderColor={isPropedeutico ? 'red.300' : 'gray.300'}
    color="gray.500"
    bg="gray.50"
    fontWeight="bold"
  >
    {string}
  </Box>
)

const TipologiaAttivita: React.FC<ITipologiaAttivita> = ({ tipologia, isPropedeutico }) => {
  switch (tipologia) {
    case Tipologie.FUORI_SERVIZIO:
      return (
        <TipologiaAvatar
          string="FS"
          color="var(--chakra-colors-teal-300)"
          isPropedeutico={isPropedeutico}
        />
      )
    case Tipologie.GUASTO:
      return (
        <TipologiaAvatar
          string="GU"
          color="var(--chakra-colors-pink-300)"
          isPropedeutico={isPropedeutico}
        />
      )
    case Tipologie.PROVVISORIO:
      return (
        <TipologiaAvatar
          string="PR"
          color="var(--chakra-colors-yellow-400)"
          isPropedeutico={isPropedeutico}
        />
      )
    case Tipologie.PROVVISORIO_GUASTO:
      return (
        <TipologiaAvatar
          string="PR"
          color="var(--chakra-colors-yellow-400)"
          isPropedeutico={isPropedeutico}
        />
      )
    default:
      return (
        <TipologiaAvatar
          string="FS"
          color="var(--chakra-colors-teal-300)"
          isPropedeutico={isPropedeutico}
        />
      )
  }
}

export default TipologiaAttivita
