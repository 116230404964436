import axios from 'axios'
import { useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'

import { API_URL, AUDIENCE_URL } from '../../../config'
import { Cestello } from '../../../types'

const useGetCestelli = () => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery('getCestelli', async (): Promise<Cestello[]> => {
    const accessToken = await getAccessTokenSilently({
      audience: AUDIENCE_URL,
    })

    const { data: cestelli } = await axios.get(`${API_URL}/cestelli`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return cestelli
  })
}

export default useGetCestelli
