import {
  Box,
  Button,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
} from '@chakra-ui/react'
import _ from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import useAssignLcl from '../../hooks/api/mutations/useAssignLcl'
import useGetLclByKeyword from '../../hooks/api/queries/useGetLclByKeyword'
import { FuoriServizio, Tipologie } from '../../types'

interface IModalAssignLcl {
  isModalAssignOpen: boolean
  setIsModalAssignOpen: (props: boolean) => void
  fuoriServizio: FuoriServizio
}

interface IModalRiepilogo {
  setIsModalAssignOpen: (props: boolean) => void
  selectedLCL: {
    id: number
    comune: string
    numero: string
    tipologia: Tipologie | null
  }
  isModalRiepilogoOpen: boolean
  setIsModalRiepilogoOpen: (props: boolean) => void
  fuoriServizio: FuoriServizio
}

const ModalAssignLcl: React.FC<IModalAssignLcl> = ({
  isModalAssignOpen,
  setIsModalAssignOpen,
  fuoriServizio,
}) => {
  //===========
  //STATE REACT
  //===========
  const [keyword, setKeyword] = useState('')
  const [keywordUI, setKeywordUI] = useState('')
  const [isModalRiepilogoOpen, setIsModalRiepilogoOpen] = useState(false)
  const [selectedLCL, setSelectedLCL] = useState<{
    id: number
    comune: string
    numero: string
    tipologia: null | Tipologie
  }>({
    id: 0,
    comune: '',
    numero: '',
    tipologia: null,
  })

  //====================
  //QUERIES AND MUTATION
  //====================
  const {
    data: lcl,
    refetch,
    isFetching,
  } = useGetLclByKeyword({
    keyword,
    tecnico: fuoriServizio.letteraConsegnaLavoro.tecnico.id,
    enabled: keyword.length > 2,
    tipologie:
      fuoriServizio.tipologia === Tipologie.PROVVISORIO_GUASTO
        ? [Tipologie.GUASTO]
        : [Tipologie.FUORI_SERVIZIO],
  })

  //===============
  //EVENT HANDLERS
  //===============
  const handleCloseModalAssignLcl = () => setIsModalAssignOpen(false)

  const debouncedKeyword = _.debounce(setKeyword, 700)

  const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLCL({ id: 0, comune: '', numero: '', tipologia: null })
    setKeywordUI(event.target.value)
    debouncedKeyword(event.target.value)
  }
  const handleSubmit = (event: any) => {
    event.preventDefault()
    refetch()
  }

  //=======
  //RENDER
  //=======
  return (
    <>
      <Modal
        isOpen={isModalAssignOpen}
        onClose={handleCloseModalAssignLcl}
        size="2xl"
        closeOnOverlayClick={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {fuoriServizio.tipologia === Tipologie.PROVVISORIO_GUASTO
              ? 'Ricerca guasto da assegnare a questo provvisorio'
              : 'Ricerca LCL da assegnare a questo provvisorio'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack as="form" onSubmit={handleSubmit}>
              <InputGroup>
                <Input
                  type="text"
                  placeholder={
                    fuoriServizio.tipologia === Tipologie.PROVVISORIO_GUASTO
                      ? 'Assegna guasto...'
                      : 'Assegna lcl...'
                  }
                  fontSize="xs"
                  h={6}
                  borderRadius="full"
                  border="1px solid"
                  borderColor="gray.400"
                  value={keywordUI}
                  onChange={handleChangeKeyword}
                />
                <InputRightElement pointerEvents="none" color="gray.300" h={6}>
                  {isFetching ? <Spinner size="xs" /> : <BsSearch />}
                </InputRightElement>
              </InputGroup>
              <Box
                alignItems="flex-start"
                w="100%"
                overflowY="auto"
                maxH="400px"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '10px' /* width of the entire scrollbar */,
                  },
                  '&::-webkit-scrollbar-track': {
                    background: 'transparent' /* color of the tracking area */,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor:
                      'var(--chakra-colors-gray-400)' /* color of the scroll thumb */,
                    borderRadius: '20px' /* roundness of the scroll thumb */,
                    border: '3px solid white' /* creates padding around scroll thumb */,
                  },
                }}
              >
                <Grid templateColumns="repeat(3, 1fr)" gap="4">
                  {lcl?.map((lettera) => (
                    <GridItem key={lettera.id}>
                      <Box
                        key={lettera.id}
                        w="100%"
                        color={selectedLCL.id === lettera.id ? 'gray.800' : 'gray.600'}
                        backgroundColor={selectedLCL.id === lettera.id ? 'gray.200' : 'white'}
                        cursor="pointer"
                        rounded="lg"
                        onClick={() =>
                          setSelectedLCL({
                            id: lettera.id,
                            comune: lettera.comune,
                            numero: lettera.numero,
                            tipologia: lettera.tipologia,
                          })
                        }
                      >
                        <VStack w="100%">
                          <Box
                            border="1px solid"
                            borderColor={selectedLCL.id === lettera.id ? 'gray.600' : 'gray.200'}
                            w="100%"
                            p={1}
                            rounded="lg"
                            _hover={{
                              textColor: 'var(--chakra-colors-gray-600)',
                              background: 'var(--chakra-colors-gray-200)',
                              borderColor: `${
                                selectedLCL.id === lettera.id ? 'gray.600' : 'inherit'
                              }`,
                            }}
                          >
                            <Box fontWeight="bold">{lettera.comune}</Box>
                            <Box color="gray.500" fontSize="xs">
                              {lettera.numero}
                            </Box>
                          </Box>
                        </VStack>
                      </Box>
                    </GridItem>
                  ))}
                </Grid>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter borderRadius="md">
            <Button
              variant="ghost"
              size="sm"
              colorScheme="gray"
              onClick={handleCloseModalAssignLcl}
            >
              Annulla
            </Button>
            <Button
              variant="solid"
              size="sm"
              colorScheme="blue"
              onClick={() => setIsModalRiepilogoOpen(true)}
            >
              Assegna
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ModalRiepilogo
        setIsModalAssignOpen={setIsModalAssignOpen}
        selectedLCL={selectedLCL}
        isModalRiepilogoOpen={isModalRiepilogoOpen}
        setIsModalRiepilogoOpen={setIsModalRiepilogoOpen}
        fuoriServizio={fuoriServizio}
      />
    </>
  )
}

export default ModalAssignLcl

const ModalRiepilogo: FC<IModalRiepilogo> = ({
  setIsModalAssignOpen,
  selectedLCL,
  setIsModalRiepilogoOpen,
  isModalRiepilogoOpen,
  fuoriServizio,
}) => {
  const { mutate: assignLcl, isSuccess } = useAssignLcl()

  const handleAssignLcl = (fuoriServizioId: number) => () => {
    assignLcl({ fuoriServizioId, letteraConsegnaLavoroId: selectedLCL.id })
  }
  const handleCloseModal = () => setIsModalRiepilogoOpen(false)

  useEffect(() => {
    if (isSuccess) {
      setIsModalRiepilogoOpen(false)
      setIsModalAssignOpen(false)
    }
  }, [isSuccess, setIsModalAssignOpen, setIsModalRiepilogoOpen])
  return (
    <Modal
      isOpen={isModalRiepilogoOpen}
      onClose={handleCloseModal}
      size="lg"
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sicuro?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box as="h3" fontSize="md">
            Sei sicuro di voler assegnare il provvisorio{' '}
            <strong>
              {fuoriServizio.letteraConsegnaLavoro.numero} -{' '}
              {fuoriServizio.letteraConsegnaLavoro.comune}
            </strong>{' '}
            all'attività:{' '}
            <strong>
              {selectedLCL.numero} - {selectedLCL.comune}
            </strong>
          </Box>
        </ModalBody>
        <ModalFooter borderRadius="md">
          <Button
            variant="solid"
            size="sm"
            colorScheme="blue"
            onClick={handleAssignLcl(fuoriServizio.id)}
          >
            Si, Assegna
          </Button>
          <Button variant="ghost" size="sm" colorScheme="gray" onClick={handleCloseModal}>
            Annulla
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
