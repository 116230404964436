// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from '@auth0/auth0-react'
import { Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import useGetFuoriServizi from '../../hooks/api/queries/useGetFuoriServizi'
import useGetRisorseGiornata from '../../hooks/api/queries/useGetRisorseGiornata'
import useGetTecnici from '../../hooks/api/queries/useGetTecnici'
import { getDisponibiliPerGiorno } from '../../lib/getDisponibiliPerGiorno'
import RisorseDisponibili from './RisorseDisponibili/RisorseDisponibili'
import TecnicoFuoriServizi from './TecnicoFuoriServizi'

interface ICalendar {
  setModalCreateFuoriServizio: ({ date, tecnicoId }: { date: string; tecnicoId: number }) => void
  setLunedi: (giorno: string) => void
  lunedi: string
  filterTipologia: string
  filterPropedeutico: boolean
}
const giorni = [0, 1, 2, 3, 4, 5, 6]

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.log(error, info)
}

const ErrorFallbackRow = ({ error }: { error: Error }) => {
  return (
    <Td
      colSpan={10}
      padding={2}
      textColor={'red.600'}
      backgroundColor={'red.50'}
      fontWeight="semibold"
    >
      Errore nella riga
    </Td>
  )
}

const Calendar: React.FC<ICalendar> = ({
  setModalCreateFuoriServizio,
  setLunedi,
  lunedi,
  filterTipologia,
  filterPropedeutico,
}) => {
  const textColor = useColorModeValue('gray.600', 'gray.300')
  const hoverColor = { color: 'gray.900' }
  //================
  // Q U E R I E S
  //================
  const { data: disponibilitaGiorni } = useGetRisorseGiornata(lunedi)
  const { data: fuoriServizi } = useGetFuoriServizi(lunedi, filterTipologia, filterPropedeutico)
  const { data: tecnici } = useGetTecnici()

  const { user } = useAuth0()

  //============================
  // E V E N T   H A N D L E R
  //============================
  const handleSetLunedi = (week: string) => (event: React.MouseEvent<HTMLElement>) => {
    setLunedi(
      dayjs(lunedi)
        .add(week === 'prev' ? -7 : week === 'next' ? 7 : 0, 'day')
        .format('YYYY-MM-DD')
    )
  }

  const handleAddFuoriServizio = (day: string, tecnicoId: number) => () => {
    setModalCreateFuoriServizio({ date: day, tecnicoId })
  }

  if (!(fuoriServizi && tecnici)) {
    return null
  }

  const tecniciFuoriServizi = tecnici
    .map((tecnico) => {
      return {
        tecnico,
        fuoriServizio: fuoriServizi.filter(
          (fuoriServizio) => fuoriServizio.letteraConsegnaLavoro.tecnico?.id === tecnico.id
        ),
      }
    })
    .sort((first) => {
      if (first.tecnico.email === user?.email) {
        return -1
      }
      return 1
    })

  return (
    <Table
      variant="unstyled"
      style={{
        fontVariantNumeric: 'normal',
      }}
    >
      <Thead>
        <Tr>
          <Th
            position="sticky"
            top="0"
            backgroundColor="white"
            zIndex="1000"
            boxShadow="3px 0 3px rgba(0,0,0,0.2)"
            // border="1px solid rgba(0,0,0,0.1)"
            padding={2}
            h="100%"
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              margin="0 0 0 auto"
              width="30px"
              height="30px"
              fontSize="20"
              color={textColor}
              backgroundColor="gray.100"
              borderRadius="4"
              cursor="pointer"
              onClick={handleSetLunedi('prev')}
              _hover={hoverColor}
            >
              <FiChevronsLeft />
            </Flex>
          </Th>
          {giorni.map((day) => {
            const isOggi =
              dayjs().format('DD/MM/YYYY') === dayjs(lunedi).add(day, 'day').format('DD/MM/YYYY')

            const data = dayjs(lunedi).add(day, 'day')

            return (
              <Th
                key={day}
                position="sticky"
                top="0"
                padding={2}
                backgroundColor="white"
                boxShadow="3px 0 3px rgba(0,0,0,0.2)"
                // border="1px solid rgba(0,0,0,0.1)"
                zIndex="1000"
                textTransform="lowercase"
                textAlign="center"
                letterSpacing="0"
                fontSize="sm"
                fontWeight="normal"
                color="gray.600"
              >
                <Flex alignItems="center" justifyContent="center">
                  <Flex>{data.format('ddd')}</Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    marginX="1"
                    width={isOggi ? '30px' : 'auto'}
                    height={isOggi ? '30px' : 'auto'}
                    fontSize="lg"
                    fontWeight={isOggi ? 'semibold' : 'normal'}
                    color={isOggi ? 'white' : 'gray.800'}
                    backgroundColor={isOggi ? 'red.400' : 'white'}
                    borderRadius="full"
                  >
                    {data.format('DD')}
                  </Flex>
                  <Flex>{data.format('MMM')}</Flex>
                </Flex>
              </Th>
            )
          })}
          <Th
            position="sticky"
            top="0"
            backgroundColor="white"
            zIndex="1000"
            boxShadow="3px 0 3px rgba(0,0,0,0.2)"
            padding={2}
            height="100%"
            width="46px"
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              width="30px"
              height="30px"
              fontSize="20"
              color={textColor}
              backgroundColor="gray.100"
              borderRadius="4"
              cursor="pointer"
              onClick={handleSetLunedi('next')}
              _hover={hoverColor}
            >
              <FiChevronsRight />
            </Flex>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        <RisorseDisponibili
          disponibiliPerGiorno={getDisponibiliPerGiorno(disponibilitaGiorni, fuoriServizi)}
        />

        {tecniciFuoriServizi.map((tecnicoFuoriServizi) => {
          return (
            <ErrorBoundary
              FallbackComponent={ErrorFallbackRow}
              onError={myErrorHandler}
              key={tecnicoFuoriServizi.tecnico.id}
            >
              <TecnicoFuoriServizi
                giorni={giorni}
                lunedi={lunedi}
                tecnicoFuoriServizi={tecnicoFuoriServizi}
                onAddFuoriServizio={handleAddFuoriServizio}
              />
            </ErrorBoundary>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default Calendar
