import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react'
import { useToast } from '@chakra-ui/toast'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { API_URL, AUDIENCE_URL } from '../../../config'
import { FuoriServizioUpdate } from '../../../types'

const updateFuoriServizio = async (
  { id, letteraConsegnaLavoroId, propedeutico, ...rest }: FuoriServizioUpdate,
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
) => {
  const accessToken = await getAccessTokenSilently({
    audience: AUDIENCE_URL,
  })
  return await axios.patch(`${API_URL}/fuori-servizio/${id}`, rest, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const useUpdateFuoriServizio = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { getAccessTokenSilently } = useAuth0()

  return useMutation(
    (fuoriServizio: FuoriServizioUpdate) => {
      return updateFuoriServizio(fuoriServizio, getAccessTokenSilently)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getFuoriServizi')
        queryClient.invalidateQueries('getFuoriServizioById')
        toast({
          title: 'Salva',
          description: 'Dati modificati',
          status: 'success',
          variant: 'left-accent',
          duration: 3000,
          position: 'bottom',
          isClosable: true,
        })
      },
      onError: () => {
        toast({
          title: 'Errore',
          description: 'Qualcosa è andato storto',
          status: 'error',
          variant: 'left-accent',
          duration: 3000,
          position: 'bottom',
          isClosable: true,
        })
      },
    }
  )
}

export default useUpdateFuoriServizio
