import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import { API_URL, AUDIENCE_URL } from '../../../config'
import { FuoriServizio } from '../../../types'

const useGetFuoriServizi = (from?: string, tipologia?: string, propedeutico?: boolean) => {
  const { getAccessTokenSilently } = useAuth0()
  const to = dayjs(from).add(6, 'day').format('YYYY-MM-DD')

  return useQuery(
    ['getFuoriServizi', { from, tipologia, propedeutico }],
    async (): Promise<FuoriServizio[]> => {
      const accessToken = await getAccessTokenSilently({
        audience: AUDIENCE_URL,
      })
      const tipologiaFilter = tipologia ? `&tipologia=${tipologia}` : ''
      const propedeuticiFilter = propedeutico ? `&propedeutico=true` : ''
      const { data: fuoriServizi } = await axios.get(
        `${API_URL}/fuori-servizio?from=${from}&to=${to}${tipologiaFilter}${propedeuticiFilter}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

      return fuoriServizi
    }
  )
}

export default useGetFuoriServizi