import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/toast'

import { RisorseGiornataUpdate } from '../../../types'
import { API_URL, AUDIENCE_URL } from '../../../config'
import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react'

const updateRisorseGiornata = async (
  { data, ...rest }: RisorseGiornataUpdate,
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
) => {
  const accessToken = await getAccessTokenSilently({
    audience: AUDIENCE_URL,
  })
  return await axios.patch(`${API_URL}/risorse-giornata/${data}`, rest, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const useUpdateRisorseGiornata = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { getAccessTokenSilently } = useAuth0()

  return useMutation(
    (risorseGiornata: RisorseGiornataUpdate) => {
      return updateRisorseGiornata(risorseGiornata, getAccessTokenSilently)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getRisorseGiornata')
        toast({
          title: 'Salva',
          description: 'Dati modificati',
          status: 'success',
          variant: 'left-accent',
          duration: 3000,
          position: 'bottom',
          isClosable: true,
        })
      },
    }
  )
}

export default useUpdateRisorseGiornata
