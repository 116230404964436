import { AllocazioneRisorse, DisponibilitaGiorno, FuoriServizio, Status } from '../types'
import { getNomeAllocazioneRisorse } from './getDisponibiliPerGiorno'
import { getStatoApprovazione } from './getStatoApprovazione'

interface GetMaxValuePrepostiProps {
  isNew: boolean
  risorseDisponibiliOggi?: DisponibilitaGiorno
  isPreposto: boolean
  allocazioneRisorse: AllocazioneRisorse
  fuoriServizio: FuoriServizio
  isDataDirty: boolean | undefined
  isAllocazioneRisorseDirty: boolean | undefined
}

const getMaxValuePreposti = (props: GetMaxValuePrepostiProps) => {
  const campoFuoriServizio = props.isPreposto ? 'numeroPreposti' : 'numeroAiutanti'

  const persone = props.isPreposto
    ? props.risorseDisponibiliOggi?.preposti
    : props.risorseDisponibiliOggi?.aiutanti
  const campoAllocazione = getNomeAllocazioneRisorse(props.allocazioneRisorse)
  const statoFS = getStatoApprovazione(props.fuoriServizio)

  if (!persone) return 0
  const RESULT = persone[campoAllocazione]

  if (statoFS === Status.BOZZA) {
    return RESULT
  }

  if (
    props.isNew ||
    props.isDataDirty ||
    props.isAllocazioneRisorseDirty ||
    (props.fuoriServizio && !props.fuoriServizio[campoFuoriServizio])
  ) {
    //IF IN HERE IT MEANS THAT THE FORM IS NEW OR THAT THERE ISNT A VALUE FOR THE FIELD campoFuoriServizio
    return RESULT
  }

  const prepostiGiaEsistentiDaSommare =
    props.fuoriServizio && props.fuoriServizio[campoFuoriServizio] === null
      ? 0
      : props.fuoriServizio[campoFuoriServizio]

  return RESULT + prepostiGiaEsistentiDaSommare!
}

export default getMaxValuePreposti
