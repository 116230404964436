import { useAuth0 } from '@auth0/auth0-react'
import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { API_URL, AUDIENCE_URL } from '../../../config'

const useAssignLcl = () => {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const toast = useToast()

  return useMutation(
    async ({
      fuoriServizioId,
      letteraConsegnaLavoroId,
    }: {
      fuoriServizioId: number
      letteraConsegnaLavoroId: number
    }) => {
      const accessToken = await getAccessTokenSilently({
        audience: AUDIENCE_URL,
      })
      return await axios.post(
        `${API_URL}/fuori-servizio/${fuoriServizioId}/associa-lcl`,
        { letteraConsegnaLavoroId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getFuoriServizi')
        queryClient.invalidateQueries('getLclByKeyword')
        toast({
          title: 'Assegnazione',
          description: 'Assegnata LCL',
          status: 'success',
          variant: 'left-accent',
          duration: 3000,
          position: 'bottom',
          isClosable: true,
        })
      },
    }
  )
}

export default useAssignLcl
