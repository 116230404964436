import axios from 'axios'
import { useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'

import { API_URL, AUDIENCE_URL } from '../../../config'
import { LetteraConsegnaLavoro, Tipologie } from '../../../types'

const useGetLclByKeyword = ({
  keyword,
  enabled = true,
  tecnico,
  tipologie,
}: {
  keyword: string
  tecnico: number
  enabled?: boolean
  tipologie?: Tipologie[]
}) => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery(
    ['getLclByKeyword', { keyword, tecnico, tipologie }],
    async (): Promise<LetteraConsegnaLavoro[]> => {
      const accessToken = await getAccessTokenSilently({
        audience: AUDIENCE_URL,
      })

      const queryTipologie = tipologie ? `&tipologie=${tipologie}` : ''
      const { data } = await axios.get(
        `${API_URL}/lettere-consegna-lavoro?q=${keyword}&tecnicoId=${tecnico}${queryTipologie}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      return data
    },
    { enabled }
  )
}

export default useGetLclByKeyword
