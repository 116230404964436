import { FuoriServizio, FuoriServizioForm, Status } from '../types'

export const getStatoApprovazione = (fuoriServizio?: FuoriServizio | FuoriServizioForm): Status => {
  if (!fuoriServizio) {
    return Status.BOZZA
  }
  if (
    !(
      fuoriServizio.data &&
      !(fuoriServizio.oraInizio === null || isNaN(+fuoriServizio.oraInizio)) &&
      !(fuoriServizio.oraFine === null || isNaN(+fuoriServizio.oraFine)) &&
      (fuoriServizio.numeroPreposti || fuoriServizio.numeroPreposti === 0) &&
      (fuoriServizio.numeroAiutanti || fuoriServizio.numeroAiutanti === 0)
    )
  ) {
    return Status.BOZZA
  }
  if (fuoriServizio.stato === Status.APPROVATO) {
    return Status.APPROVATO
  }
  if (fuoriServizio.stato === Status.DA_APPROVARE) {
    return Status.DA_APPROVARE
  }
  if (fuoriServizio.stato === Status.FATTO) {
    return Status.FATTO
  }
  if (fuoriServizio.stato === Status.SALTATO) {
    return Status.SALTATO
  }
  return Status.BOZZA
}
