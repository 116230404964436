import { useAuth0 } from '@auth0/auth0-react'
import { AddIcon } from '@chakra-ui/icons'
import { Box, Flex } from '@chakra-ui/layout'
import { Td, Tr } from '@chakra-ui/table'
import dayjs from 'dayjs'
import { ErrorBoundary } from 'react-error-boundary'

import { GIORNI_ANTICIPO_MIN } from '../../config'
import { isRoleCapoTecnico } from '../../lib/isRoleCapoTecnico'
import { FuoriServizio, Tecnico } from '../../types'
import FuoriServizioCard from './FuoriServizioCard'

interface TecnicoFuoriServiziProps {
  giorni: number[]
  lunedi: string
  tecnicoFuoriServizi: {
    tecnico: Tecnico
    fuoriServizio: FuoriServizio[]
  }
  onAddFuoriServizio: (day: string, tecnicoId: number) => () => void
}

const ErrorFallbackFuoriServizio = ({ error }: { error: Error }) => {
  return (
    <Box
      marginBottom="2"
      paddingInline="2"
      paddingBlock="1"
      fontSize="xs"
      textAlign="center"
      textColor={'red.600'}
      bg={'red.50'}
      borderRadius="4"
      border="solid 2px var(--chakra-colors-red-300)"
      fontWeight="bold"
    >
      Errore nel fuori servizio
    </Box>
  )
}

const TecnicoFuoriServizi: React.FC<TecnicoFuoriServiziProps> = ({
  giorni,
  lunedi,
  tecnicoFuoriServizi,
  onAddFuoriServizio,
}) => {
  const { user } = useAuth0()

  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    console.log(error, info)
  }

  if (!user) return <></>
  const isCapoTecnico = isRoleCapoTecnico(user)
  return (
    <Tr>
      <Td
        width="190px"
        padding={2}
        border="1px solid rgba(0,0,0,0.1)"
        position="sticky"
        left="0"
        bg="white"
        fontWeight={user.email === tecnicoFuoriServizi.tecnico.email ? 'extrabold' : 'normal'}
      >
        {tecnicoFuoriServizi.tecnico.nome} {tecnicoFuoriServizi.tecnico.cognome}
      </Td>
      {/* <Td padding={2} border="1px solid rgba(0,0,0,0.1)"></Td> */}
      {giorni.map((day) => {
        return (
          <Td
            padding={2}
            border="1px solid rgba(0,0,0,0.1)"
            height="100%"
            key={day}
            bg={
              dayjs(lunedi).add(day, 'day').startOf('day').diff(dayjs().startOf('day'), 'days') >=
              GIORNI_ANTICIPO_MIN
                ? 'white'
                : 'gray.50'
            }
          >
            <Flex flexDirection="column" height="100%">
              {tecnicoFuoriServizi.fuoriServizio.map((servizio) => (
                <ErrorBoundary
                  key={servizio.id}
                  FallbackComponent={ErrorFallbackFuoriServizio}
                  onError={myErrorHandler}
                >
                  <FuoriServizioCard
                    fuoriServizio={servizio}
                    data={dayjs(lunedi).add(day, 'day').format()}
                    tecnico={tecnicoFuoriServizi.tecnico}
                  />
                </ErrorBoundary>
              ))}
              {isCapoTecnico || user.email === tecnicoFuoriServizi.tecnico.email ? (
                <Flex
                  flex="1"
                  alignItems="center"
                  justifyContent="center"
                  padding="2"
                  fontSize="12"
                  color="gray.600"
                  backgroundColor="gray.100"
                  borderRadius="4"
                  cursor="pointer"
                  opacity="0"
                  transition="opacity 200ms ease-in-out"
                  onClick={onAddFuoriServizio(
                    dayjs(lunedi).add(day, 'day').format('YYYY-MM-DD'),
                    tecnicoFuoriServizi.tecnico.id
                  )}
                  _hover={{ opacity: 1 }}
                >
                  <AddIcon />
                </Flex>
              ) : (
                <></>
              )}
            </Flex>
          </Td>
        )
      })}
      <Td
        padding="0"
        // border="1px solid rgba(0,0,0,0.1)"
      ></Td>
    </Tr>
  )
}

export default TecnicoFuoriServizi
