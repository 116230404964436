import {
  Box,
  Checkbox,
  // Slider,
  // SliderFilledTrack,
  // SliderThumb,
  // SliderTrack,
  HStack,
  Select,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import getLocalStorageField from '../../lib/localStorage/getLocalStorageField'
import setLocalstorageField from '../../lib/localStorage/setLocalstorageField'
import { Tipologie } from '../../types'
import Calendar from './Calendar'
import ModalCreate from './ModalCreate'

const FuoriServizio = () => {
  const [lunedi, setLunedi] = useState(dayjs().day(1).format('YYYY-MM-DD'))
  const [filterTipologia, setFilterTipologia] = useState<string>('')
  const [filterPropedeutico, setFilterPropedeutico] = useState<boolean>(
    getLocalStorageField('propedeutico') === 'true' ? true : false
  )

  const [modalCreateFuoriServizio, setModalCreateFuoriServizio] = useState({
    date: '',
    tecnicoId: 0,
  })

  return (
    <>
      <HStack
        position="sticky"
        left="0"
        justify="flex-start"
        spacing={10}
        bg="gray.50"
        border="1px solid"
        borderColor="gray.300"
        px="2"
        py="4"
        rounded="lg"
        marginRight="2"
        marginBottom="6"
      >
        <Box>
          <Select
            backgroundColor="white"
            placeholder="Tutte le attività"
            value={filterTipologia}
            onChange={(e) => setFilterTipologia(e.target.value)}
          >
            <option value={Tipologie.FUORI_SERVIZIO}>Fuori servizio</option>
            <option value={Tipologie.GUASTO}>Guasto</option>
            <option value={Tipologie.PROVVISORIO}>Provvisorio</option>
            <option value={Tipologie.PROVVISORIO_GUASTO}>Provvisorio guasto</option>
          </Select>
        </Box>

        <Checkbox
          defaultChecked={filterPropedeutico}
          onChange={() => {
            setFilterPropedeutico(!filterPropedeutico)
            setLocalstorageField('propedeutico', !filterPropedeutico)
          }}
        >
          Propedeutico
        </Checkbox>
      </HStack>
      <Calendar
        setModalCreateFuoriServizio={setModalCreateFuoriServizio}
        setLunedi={setLunedi}
        lunedi={lunedi}
        filterTipologia={filterTipologia}
        filterPropedeutico={filterPropedeutico}
      />

      {modalCreateFuoriServizio && (
        <ModalCreate
          modalCreateFuoriServizio={modalCreateFuoriServizio}
          setModalCreateFuoriServizio={setModalCreateFuoriServizio}
        />
      )}
    </>
  )
}

export default FuoriServizio
