import { Tipologie } from '../types'

const renderTipologia = (tipologia: Tipologie | null) => {
  switch (tipologia) {
    case Tipologie.FUORI_SERVIZIO:
      return 'Fuori servizio'
    case Tipologie.GUASTO:
      return 'Guasto'
    case Tipologie.PROVVISORIO:
      return 'Provvisiorio'
    case Tipologie.PROVVISORIO_GUASTO:
      return 'Provvisiorio Guasto'
    default:
      return 'Richiesta'
  }
}

export default renderTipologia
