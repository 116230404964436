import React, { useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import ReactFocusLock from 'react-focus-lock'
import { ErrorBoundary } from 'react-error-boundary'
import { Box, Flex } from '@chakra-ui/layout'
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import {
  PopoverContent,
  PopoverTrigger,
  Popover as ChakraPopover,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/popover'

import QuantityInput from '../../shared/QuantityInput'
import { DisponibilitaGiorno } from '../../../types'
import useUpdateRisorseGiornata from '../../../hooks/api/mutations/useUpdateRisorseGiornata'
import { Button } from '@chakra-ui/button'
import dayjs from 'dayjs'
import { useAuth0 } from '@auth0/auth0-react'
import { isRoleCapoTecnico } from '../../../lib/isRoleCapoTecnico'

interface RisorseGiornoProps {
  disponibiliGiorno: DisponibilitaGiorno
}

const RisorseGiorno: React.FC<RisorseGiornoProps> = ({ disponibiliGiorno }) => {
  const [numeroPreposti, setNumeroPreposti] = useState(disponibiliGiorno.preposti.numero)
  const [numeroAiutanti, setNumeroAiutanti] = useState(disponibiliGiorno.aiutanti.numero)

  const { onOpen, onClose, isOpen } = useDisclosure()
  const updateRisorseGiornata = useUpdateRisorseGiornata()

  const { user } = useAuth0()

  const handleChangeNumeroPreposti = (value: string) => {
    setNumeroPreposti(parseInt(value, 10))
  }

  const handleChangeNumeroAiutanti = (value: string) => {
    setNumeroAiutanti(parseInt(value, 10))
  }

  const handleSaveRisorseGiornata = (data: string) => (event: React.FormEvent) => {
    updateRisorseGiornata.mutate({ data, numeroPreposti, numeroAiutanti })
    onClose()
  }

  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    console.log(error, info)
  }

  const ErrorFallback = ({ error }: { error: Error }) => {
    return (
      <Box textColor={'red.900'} fontSize="small" fontWeight="bold">
        Errore nella risorsa giorno
      </Box>
    )
  }

  return (
    <Td padding={2} border="1px solid rgba(0,0,0,0.1)">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
        <ChakraPopover
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          placement="bottom"
          closeOnBlur={true}
        >
          <PopoverTrigger>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              // padding={2}
              borderRadius="4"
              aria-label="popover opener"
              cursor="pointer"
              _hover={{ backgroundColor: 'gray.200' }}
            >
              <Box fontSize="sm" color="gray.500">
                {disponibiliGiorno.preposti.numero}
              </Box>
              <Flex fontWeight="semibold" fontSize="sm">
                <Flex
                  color={disponibiliGiorno.preposti.disponibiliMattino < 0 ? 'red.500' : 'gray.900'}
                >
                  {disponibiliGiorno.preposti.disponibiliMattino}
                </Flex>
                <Flex marginX="2" color="gray.400" fontSize="xl" fontWeight="thin">
                  |
                </Flex>
                <Flex
                  color={
                    disponibiliGiorno.preposti.disponibiliPomeriggio < 0 ? 'red.500' : 'gray.900'
                  }
                >
                  {disponibiliGiorno.preposti.disponibiliPomeriggio}
                </Flex>
              </Flex>
            </Flex>
          </PopoverTrigger>
          <PopoverContent padding="5" placeContent="bottom">
            <ReactFocusLock returnFocus persistentFocus={false}>
              <PopoverArrow />
              <PopoverCloseButton />
              <Table>
                <Thead>
                  <Tr>
                    <Th padding="2"></Th>
                    <Th padding="2" textAlign="center">
                      M
                    </Th>
                    <Th padding="2" textAlign="center">
                      P
                    </Th>
                    <Th padding="2">Presenti</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td padding="2" border="none">
                      Preposti
                    </Td>
                    <Td padding="2" border="none" textAlign="center">
                      {disponibiliGiorno.preposti.disponibiliMattino}
                    </Td>
                    <Td padding="2" border="none" textAlign="center">
                      {disponibiliGiorno.preposti.disponibiliPomeriggio}
                    </Td>
                    <Td padding="2" border="none">
                      <QuantityInput
                        min={0}
                        max={50}
                        value={numeroPreposti}
                        onChange={handleChangeNumeroPreposti}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td padding="2" border="none">
                      Aiutanti
                    </Td>
                    <Td padding="2" border="none" textAlign="center">
                      {disponibiliGiorno.aiutanti.disponibiliMattino}
                    </Td>
                    <Td padding="2" border="none" textAlign="center">
                      {disponibiliGiorno.aiutanti.disponibiliPomeriggio}
                    </Td>
                    <Td padding="2" border="none">
                      <QuantityInput
                        min={0}
                        max={50}
                        value={numeroAiutanti}
                        onChange={handleChangeNumeroAiutanti}
                      />
                    </Td>
                  </Tr>
                  <Tr>
                    <Td padding="2" border="none" colSpan={4} textAlign="right">
                      <Button
                        type="submit"
                        colorScheme="blue"
                        variant="solid"
                        onClick={handleSaveRisorseGiornata(
                          dayjs(disponibiliGiorno.data).format('YYYY-MM-DD')
                        )}
                        isDisabled={user && !isRoleCapoTecnico(user)}
                      >
                        Salva
                      </Button>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </ReactFocusLock>
          </PopoverContent>
        </ChakraPopover>
      </ErrorBoundary>
    </Td>
  )
}

export default RisorseGiorno
