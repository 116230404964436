import { useAuth0 } from '@auth0/auth0-react'
import { useToast } from '@chakra-ui/toast'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { API_URL, AUDIENCE_URL } from '../../../config'

const useDeleteFuoriServizio = () => {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const toast = useToast()

  return useMutation(
    async (id: number) => {
      const accessToken = await getAccessTokenSilently({
        audience: AUDIENCE_URL,
      })
      const data = await axios.delete(`${API_URL}/fuori-servizio/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getFuoriServizi')
        toast({
          title: 'Elimina',
          description: 'Attività eliminata correttamente.',
          status: 'warning',
          variant: 'subtle',
          duration: 3000,
          position: 'bottom',
          isClosable: true,
        })
      },
    }
  )
}

export default useDeleteFuoriServizio
