import { Box } from '@chakra-ui/layout'

import dayjs from 'dayjs'
import { Tecnico } from '../../types'

interface ModalSidebarProps {
  createdAt: string
  tecnico: Tecnico
}

const ModalSidebar: React.FC<ModalSidebarProps> = ({ createdAt, tecnico }) => {
  return (
    <Box w="8rem" borderRight="1px solid var(--chakra-colors-gray-200)" pr="2">
      <Box marginBottom="1" fontSize="sm">
        Creata da:
      </Box>
      <Box fontSize="sm" fontWeight="semibold">
        {tecnico.nome} {tecnico.cognome}
      </Box>
      <Box color="gray.500" fontSize="xs">
        {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
      </Box>
    </Box>
  )
}

export default ModalSidebar
