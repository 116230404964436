import { useAuth0 } from '@auth0/auth0-react'
import { useToast } from '@chakra-ui/toast'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { API_URL, AUDIENCE_URL } from '../../../config'
import { FuoriServizioUpdate } from '../../../types'

const useCreateFuoriServizio = () => {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const toast = useToast()

  return useMutation(
    async (fuoriServizio: FuoriServizioUpdate) => {
      const accessToken = await getAccessTokenSilently({
        audience: AUDIENCE_URL,
      })

      return await axios.post(`${API_URL}/fuori-servizio`, fuoriServizio, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getFuoriServizi')
        queryClient.invalidateQueries('getLclByKeyword')
        toast({
          title: 'Creazione',
          description: 'Attivita creata con successo.',
          status: 'success',
          variant: 'left-accent',
          duration: 3000,
          position: 'bottom',
          isClosable: true,
        })
      },
    }
  )
}

export default useCreateFuoriServizio
