import axios from 'axios'
import { useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'

import { API_URL, AUDIENCE_URL } from '../../../config'
import { Preposto } from '../../../types'

const useGetPreposti = () => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery(['getPreposti'], async (): Promise<Preposto[]> => {
    const accessToken = await getAccessTokenSilently({
      audience: AUDIENCE_URL,
    })

    const { data } = await axios.get(`${API_URL}/preposti`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return data
  })
}

export default useGetPreposti
