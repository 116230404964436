import axios from 'axios'
import { useQuery } from 'react-query'
import { useAuth0 } from '@auth0/auth0-react'

import { API_URL, AUDIENCE_URL } from '../../../config'
import { Tecnico } from '../../../types'

const useGetTecnici = () => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery('getTecnici', async (): Promise<Tecnico[]> => {
    const accessToken = await getAccessTokenSilently({
      audience: AUDIENCE_URL,
    })

    const { data: tecnici } = await axios.get(`${API_URL}/tecnici`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    return tecnici
  })
}

export default useGetTecnici
