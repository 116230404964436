import React from 'react'

interface AllocazioneProps {
  allocazione: string
}

const Allocazione: React.FC<AllocazioneProps> = ({ allocazione }) => {
  return (
    <svg viewBox="0 0 22 10">
      <rect
        x="0"
        width="10"
        height="10"
        fill={allocazione === 'MATTINO' || allocazione === 'GIORNATA' ? '#68D391' : '#CBD1D5'}
        rx="2"
      />
      <rect
        x="12"
        width="10"
        height="10"
        fill={allocazione === 'POMERIGGIO' || allocazione === 'GIORNATA' ? '#68D391' : '#CBD1D5'}
        rx="2"
      />
    </svg>
  )
}

export default Allocazione
