import { useBoolean } from '@chakra-ui/hooks'
import { useBreakpointValue } from '@chakra-ui/media-query'
import React from 'react'

export const useMobileMenuState = () => {
  const [isOpen, actions] = useBoolean()
  const isMobile = useBreakpointValue({ base: true, xl: false })

  React.useEffect(() => {
    if (isMobile === false) {
      actions.off()
    }
  }, [actions, isMobile])
  return { isOpen, ...actions }
}
