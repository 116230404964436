import { User } from '@auth0/auth0-spa-js'
import { Button } from '@chakra-ui/button'
import { Box, HStack, Spacer } from '@chakra-ui/layout'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import React, { Dispatch, SetStateAction, useState } from 'react'
// import useGetFuoriServizioById from '../../hooks/api/queries/useGetFuoriServizioById'
import { getStatoApprovazione } from '../../lib/getStatoApprovazione'
import { isRoleCapoTecnico } from '../../lib/isRoleCapoTecnico'
import renderTipologia from '../../lib/renderTipologia'
import { FuoriServizio, Status, Tecnico, Tipologie } from '../../types'
import ModalForm from './ModalForm'
import ModalSidebar from './SidebarModalModifica'

interface ModalModificaProps {
  isModalOpen: { id: number; isOpen: boolean }
  setIsModalOpen: Dispatch<SetStateAction<{ isOpen: boolean; id: number }>>
  setModalDelete: Dispatch<SetStateAction<boolean>>
  setIsModalAssignOpen: (props: boolean) => void
  user: User
  tecnico: Tecnico
  fuoriServizio: FuoriServizio
}

const ModalModifica: React.FC<ModalModificaProps> = ({
  isModalOpen,
  setIsModalOpen,
  setModalDelete,
  user,
  tecnico,
  fuoriServizio,
  setIsModalAssignOpen,
}) => {
  //========================
  // HOOKS REACT QUERY
  //========================
  // const { data: fuoriServizio2 } = useGetFuoriServizioById({ id: isModalOpen.id })

  //========================
  // LOCAL STATE
  //========================

  const [saveButtonLabel, setSaveButtonLabel] = useState('Salva')

  //========================
  // EVENT HANDLER
  //========================

  const handleCloseModal = () => {
    setIsModalOpen({ isOpen: false, id: 0 })
  }

  //========================
  // DERIVED STATE
  //========================
  const statoFS = getStatoApprovazione(fuoriServizio)

  const isCapoTecnico = isRoleCapoTecnico(user)

  if (!fuoriServizio) {
    return null
  }
  const lastMinute = !!(
    fuoriServizio.letteraConsegnaLavoro.tipologia === Tipologie.PROVVISORIO ||
    fuoriServizio.letteraConsegnaLavoro.tipologia === Tipologie.PROVVISORIO_GUASTO
  )

  return (
    <Modal
      isOpen={isModalOpen.isOpen}
      onClose={handleCloseModal}
      size="5xl"
      closeOnOverlayClick={false}
      variant="left-accent"
    >
      <ModalOverlay />
      <ModalContent>
        <Box padding="1" bg="gray.300" rounded="md">
          <Box bg="white" rounded="sm">
            <ModalHeader bg="gray.300">
              <Box>
                <Box as="span" color="gray.900">
                  {renderTipologia(fuoriServizio.letteraConsegnaLavoro.tipologia)} #
                  {fuoriServizio.letteraConsegnaLavoro.numero}
                </Box>
                {lastMinute && (
                  <Button
                    onClick={() => setIsModalAssignOpen(true)}
                    colorScheme="blue"
                    cursor="pointer"
                    border="1px"
                    borderColor="blue.200"
                    px="2"
                    py="1"
                    size="sm"
                    rounded="lg"
                    marginLeft="2"
                    isDisabled={
                      statoFS === Status.BOZZA || (user.email !== tecnico.email && !isCapoTecnico)
                    }
                  >
                    {fuoriServizio.letteraConsegnaLavoro.tipologia === Tipologie.PROVVISORIO
                      ? 'Assegna LCL...'
                      : 'Assegna Guasto...'}
                  </Button>
                )}
                {fuoriServizio.letteraConsegnaLavoro.provvisorio && (
                  <Box marginLeft="2" as="span" fontSize="sm" color="gray.800">
                    (ex provvisorio: #{fuoriServizio.letteraConsegnaLavoro.provvisorio})
                  </Box>
                )}
              </Box>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack alignItems="stretch">
                <ModalSidebar createdAt={fuoriServizio.createdAt} tecnico={fuoriServizio.tecnico} />
                <Box width="100%" paddingLeft="4">
                  <ModalForm
                    fuoriServizio={fuoriServizio}
                    setSaveButtonLabel={setSaveButtonLabel}
                    handleCloseModal={handleCloseModal}
                  />
                </Box>
              </HStack>
            </ModalBody>
            <ModalFooter bg="gray.100" borderRadius="md">
              <HStack w="100%">
                <Button
                  variant="link"
                  colorScheme="red"
                  onClick={() => setModalDelete(true)}
                  isDisabled={
                    !isCapoTecnico && (user.email !== tecnico.email || statoFS !== Status.BOZZA)
                  }
                >
                  &times; Elimina
                </Button>
                <Spacer />
                <HStack>
                  <Button variant="ghost" mr={3} onClick={handleCloseModal}>
                    Annulla
                  </Button>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    form="modalForm"
                    isDisabled={
                      !(
                        isCapoTecnico ||
                        ((statoFS === Status.BOZZA || statoFS === Status.DA_APPROVARE) &&
                          user.email === tecnico.email)
                      )
                    }
                  >
                    {saveButtonLabel}
                  </Button>
                </HStack>
              </HStack>
            </ModalFooter>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default ModalModifica
