import { useAuth0 } from '@auth0/auth0-react'
import { useColorModeValue } from '@chakra-ui/color-mode'
import { Box, Flex, HStack } from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/tooltip'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { getStatoApprovazione } from '../../lib/getStatoApprovazione'
import { FuoriServizio, Preposto, Status, Tecnico } from '../../types'
import Allocazione from './Allocazione'
import ModalAssignLcl from './ModalAssignLcl'
import DeleteModal from './ModalDelete'
import ModalModifica from './ModalModifica'
import TipologiaAttivita from './TipologiaAttivita'

interface FuoriServizioProps {
  fuoriServizio: FuoriServizio
  data: string
  tecnico: Tecnico
}

const renderHour = (hour: number) => {
  const ora = Math.floor(hour)
  const numberMinuti = ((hour - ora) * 60).toLocaleString('ita', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
  return `${ora}:${numberMinuti}`
}

const FuoriServizioCard: React.FC<FuoriServizioProps> = ({ fuoriServizio, data, tecnico }) => {
  const [isModalOpen, setIsModalOpen] = useState<{
    isOpen: boolean
    id: number
  }>({
    isOpen: false,
    id: 0,
  })
  const { user } = useAuth0()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isModalAssignOpen, setIsModalAssignOpen] = useState(false)

  const statusFuoriservizio = getStatoApprovazione(fuoriServizio)

  const handleSetModalOpen =
    ({ fuoriServizio }: { fuoriServizio: FuoriServizio }) =>
    () => {
      if (statusFuoriservizio !== Status.APPROVATO) {
        return setIsModalOpen({
          isOpen: true,
          id: fuoriServizio.id,
        })
      }
      return setIsModalOpen({ isOpen: true, id: fuoriServizio.id })
    }

  const bgColor = useColorModeValue('white', 'gray.800')
  const textColor = useColorModeValue('gray.900', 'gray.400')

  if (dayjs(fuoriServizio.data).format('YYYY/MM/DD') === dayjs(data).format('YYYY/MM/DD')) {
    return (
      <Box flex="1" marginBottom="2" minW={160}>
        <Tooltip
          hasArrow
          label={
            fuoriServizio.prepostiDesiderati.length > 0 ? (
              <Box fontSize="xs">
                {fuoriServizio.prepostiDesiderati.map((preposto: Preposto) => {
                  return <Box key={preposto.id}>{preposto.nome}</Box>
                })}
              </Box>
            ) : null
          }
        >
          <Box
            fontSize="xs"
            textColor={textColor}
            bg={bgColor}
            borderRadius="4"
            border={
              statusFuoriservizio === Status.BOZZA
                ? 'dashed 2px var(--chakra-colors-gray-400)'
                : statusFuoriservizio === Status.APPROVATO
                ? 'solid 2px var(--chakra-colors-cyan-500)'
                : statusFuoriservizio === Status.FATTO
                ? 'solid 2px var(--chakra-colors-green-400)'
                : statusFuoriservizio === Status.SALTATO
                ? 'solid 2px var(--chakra-colors-red-300)'
                : 'solid 2px var(--chakra-colors-orange-300)'
            }
            cursor="pointer"
            onClick={handleSetModalOpen({ fuoriServizio })}
            _hover={{
              backgroundColor:
                statusFuoriservizio === Status.BOZZA
                  ? 'gray.50'
                  : statusFuoriservizio === Status.APPROVATO
                  ? 'cyan.50'
                  : statusFuoriservizio === Status.FATTO
                  ? 'green.50'
                  : statusFuoriservizio === Status.SALTATO
                  ? 'red.50'
                  : 'orange.50',
            }}
          >
            <Box px="2" py="px">
              <HStack w="100%" justifyContent="space-between" alignItems="center">
                <Box fontWeight="bold">{fuoriServizio.letteraConsegnaLavoro.comune}</Box>
                <TipologiaAttivita
                  tipologia={fuoriServizio.tipologia}
                  isPropedeutico={fuoriServizio.propedeutico}
                />
              </HStack>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Box lineHeight="1" color="gray.500" marginBottom="1" maxWidth="100px" isTruncated>
                  {fuoriServizio.letteraConsegnaLavoro.numero}
                </Box>
              </Flex>
            </Box>

            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="baseline"
              borderTop="1px solid"
              borderColor={
                statusFuoriservizio === Status.BOZZA
                  ? 'gray.200'
                  : statusFuoriservizio === Status.APPROVATO
                  ? 'cyan.200'
                  : statusFuoriservizio === Status.FATTO
                  ? 'green.200'
                  : statusFuoriservizio === Status.SALTATO
                  ? 'red.200'
                  : 'orange.200'
              }
              paddingX="2"
            >
              <Box>
                {fuoriServizio.oraInizio !== null ? renderHour(+fuoriServizio.oraInizio) : ''} -{' '}
                {fuoriServizio.oraFine !== null ? renderHour(+fuoriServizio.oraFine) : ''}
              </Box>
              <Flex justifyContent="space-between" alignItems="baseline">
                {statusFuoriservizio !== Status.BOZZA && statusFuoriservizio !== Status.SALTATO && (
                  <Box w="16px" marginRight="1">
                    <Allocazione allocazione={fuoriServizio.allocazioneRisorse} />
                  </Box>
                )}
                <Box fontWeight="semibold">
                  {fuoriServizio.numeroPreposti === null ? '/' : fuoriServizio.numeroPreposti}
                </Box>
                <Box color="gray.400">+</Box>
                <Box fontWeight="semibold">
                  {fuoriServizio.numeroAiutanti === null ? '/' : fuoriServizio.numeroAiutanti}
                </Box>
              </Flex>
            </Flex>
          </Box>
        </Tooltip>
        {isModalOpen.isOpen && user && (
          <ModalModifica
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setModalDelete={setIsDeleteModalOpen}
            setIsModalAssignOpen={setIsModalAssignOpen}
            user={user}
            tecnico={tecnico}
            fuoriServizio={fuoriServizio}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteModal
            isDeleteModalOpen={isDeleteModalOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            fuoriServizio={fuoriServizio}
          />
        )}
        {isModalAssignOpen && (
          <ModalAssignLcl
            setIsModalAssignOpen={setIsModalAssignOpen}
            isModalAssignOpen={isModalAssignOpen}
            fuoriServizio={fuoriServizio}
          />
        )}
      </Box>
    )
  }
  return null
}

export default FuoriServizioCard
