import { Auth0Provider } from '@auth0/auth0-react'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AUDIENCE_URL, CLIENT_ID, DOMAIN_ISSUER, REDIRECT_URI } from './config'
import Dashboard from './pages/Dashboard'
// import Login from './pages/Login'
import theme from './theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

function App() {
  return (
    <Router>
      <Auth0Provider
        audience={AUDIENCE_URL}
        domain={DOMAIN_ISSUER}
        clientId={CLIENT_ID}
        redirectUri={REDIRECT_URI}
      >
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools /> */}
          <ChakraProvider theme={theme}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <Routes>
              <Route path="/*" element={<Dashboard />} />
            </Routes>
            <ReactQueryDevtools initialIsOpen={false} />
          </ChakraProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </Router>
  )
}

export default App
