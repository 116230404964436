import { Td, Tr } from '@chakra-ui/table'
import RisorseGiorno from './RisorseGiorno'
import { Box } from '@chakra-ui/layout'
import { DisponibilitaGiorno } from '../../../types'

interface RisorseDisponibiliProps {
  disponibiliPerGiorno?: DisponibilitaGiorno[]
}

const RisorseDisponibili: React.FC<RisorseDisponibiliProps> = ({
  disponibiliPerGiorno,
}) => {

  return (
    <Tr backgroundColor="gray.100">
      <Td
        padding={2}
        border="1px solid rgba(0,0,0,0.1)"
        fontWeight="semibold"
        textColor="gray.700"
        fontSize="sm"
        position="sticky"
        left="0"
        bg="gray.100"
      >
        <Box>Preposti</Box>
        <Box>Disponibili (M | P)</Box>
      </Td>
      {/* <Td padding={2} border="1px solid rgba(0,0,0,0.1)"></Td> */}
      {disponibiliPerGiorno?.map((disponibiliGiorno) => {
        return (
          <RisorseGiorno disponibiliGiorno={disponibiliGiorno} key={disponibiliGiorno.data} />
        )
      })}
      <Td
        backgroundColor="white"
        padding={0}
        // border="1px solid rgba(0,0,0,0.1)"
      ></Td>
    </Tr>
  )
}

export default RisorseDisponibili
