import { useAuth0 } from '@auth0/auth0-react'
import { CheckCircleIcon, ViewOffIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons'
import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Input,
  InputAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { HiThumbUp } from 'react-icons/hi'
import MultiSelect from '../../components/shared/MultiSelect'
import { GIORNI_ANTICIPO_MIN } from '../../config'
import useCreateFuoriServizio from '../../hooks/api/mutations/useCreateFuoriServizio'
import useUpdateFuoriServizio from '../../hooks/api/mutations/useUpdateFuoriServizio'
import useGetCestelli from '../../hooks/api/queries/useGetCestelli'
import useGetFuoriServizi from '../../hooks/api/queries/useGetFuoriServizi'
import useGetPreposti from '../../hooks/api/queries/useGetPreposti'
import useGetRisorseGiornata from '../../hooks/api/queries/useGetRisorseGiornata'
import useGetTecnici from '../../hooks/api/queries/useGetTecnici'
import { getCestelliDisponibiliGiorno } from '../../lib/getCestelliDisponibiliGiorno'
import {
  getDisponibiliPerGiorno,
  getNomeAllocazioneRisorse,
} from '../../lib/getDisponibiliPerGiorno'
import getMaxValuePreposti from '../../lib/getMaxValuePreposti'
import { getStatoApprovazione } from '../../lib/getStatoApprovazione'
import { isRoleCapoTecnico } from '../../lib/isRoleCapoTecnico'
import {
  AllocazioneRisorse,
  Cestello,
  FuoriServizio,
  FuoriServizioForm,
  FuoriServizioUpdate,
  Preposto,
  Status,
  Tipologie,
} from '../../types'

const ORE = [
  { value: 0, label: '00:00' },
  { value: 0.5, label: '00:30' },
  { value: 1.0, label: '01:00' },
  { value: 1.5, label: '01:30' },
  { value: 2.0, label: '02:00' },
  { value: 2.5, label: '02:30' },
  { value: 3.0, label: '03:00' },
  { value: 3.5, label: '03:30' },
  { value: 4.0, label: '04:00' },
  { value: 4.5, label: '04:30' },
  { value: 5.0, label: '05:00' },
  { value: 5.5, label: '05:30' },
  { value: 6.0, label: '06:00' },
  { value: 6.5, label: '06:30' },
  { value: 7.0, label: '07:00' },
  { value: 7.5, label: '07:30' },
  { value: 8.0, label: '08:00' },
  { value: 8.5, label: '08:30' },
  { value: 9.0, label: '09:00' },
  { value: 9.5, label: '09:30' },
  { value: 10.0, label: '10:00' },
  { value: 10.5, label: '10:30' },
  { value: 11.0, label: '11:00' },
  { value: 11.5, label: '11:30' },
  { value: 12.0, label: '12:00' },
  { value: 12.5, label: '12:30' },
  { value: 13.0, label: '13:00' },
  { value: 13.5, label: '13:30' },
  { value: 14.0, label: '14:00' },
  { value: 14.5, label: '14:30' },
  { value: 15.0, label: '15:00' },
  { value: 15.5, label: '15:30' },
  { value: 16.0, label: '16:00' },
  { value: 16.5, label: '16:30' },
  { value: 17.0, label: '17:00' },
  { value: 17.5, label: '17:30' },
  { value: 18.0, label: '18:00' },
  { value: 18.5, label: '18:30' },
  { value: 19.0, label: '19:00' },
  { value: 19.5, label: '19:30' },
  { value: 20.0, label: '20:00' },
  { value: 20.5, label: '20:30' },
  { value: 21.0, label: '21:00' },
  { value: 21.5, label: '21:30' },
  { value: 22.0, label: '22:00' },
  { value: 22.5, label: '22:30' },
  { value: 23.0, label: '23:00' },
  { value: 23.5, label: '23:30' },
]

interface ModalFormProps {
  fuoriServizio?: FuoriServizio
  modalCreateFuoriServizio?: { date: string; tecnicoId: number }
  setSaveButtonLabel: (props: string) => void
  lcl?: { id: number; comune: string; numero: string; tipologia: Tipologie | null }
  handleCloseModal: () => void
}

const ModalForm: React.FC<ModalFormProps> = ({
  fuoriServizio,
  modalCreateFuoriServizio,
  setSaveButtonLabel,
  handleCloseModal,
  lcl,
}) => {
  const { tipologia, createdAt, tecnico, ...FS } = !!fuoriServizio
    ? fuoriServizio
    : ({} as FuoriServizio)

  const isInCreazione = !!lcl?.id

  //========================
  // HOOKS AUTH 0
  //========================

  const { user } = useAuth0()

  //========================
  // HOOKS REACT QUERY
  //========================

  const { data: cestelli } = useGetCestelli()
  const { data: preposti } = useGetPreposti()
  const { data: tecnici } = useGetTecnici()

  const updateFuoriServizio = useUpdateFuoriServizio()
  const createFuoriServizio = useCreateFuoriServizio()

  const fuoriServizioForm: FuoriServizioForm = isInCreazione
    ? {
        id: FS.id,
        comune: lcl.comune,
        tecnicoId: modalCreateFuoriServizio?.tecnicoId,
        data: modalCreateFuoriServizio?.date || '',
        oraInizio: 0,
        oraFine: 0,
        allocazioneRisorse: AllocazioneRisorse.SELEZIONA,
        stato: Status.DA_APPROVARE,
        motivazioneSaltato: '',
        numeroPreposti: '',
        prepostiDesiderati: [],
        cestelli: [],
        //propedeutico settato false se il giorno selezionato è dopo i giorni minimi altrimenti true e readonly
        propedeutico:
          dayjs(modalCreateFuoriServizio?.date).diff(dayjs().startOf('day'), 'days') >=
          GIORNI_ANTICIPO_MIN
            ? false
            : lcl.tipologia === Tipologie.PROVVISORIO ||
              lcl.tipologia === Tipologie.PROVVISORIO_GUASTO ||
              lcl.tipologia === Tipologie.GUASTO
            ? false
            : true,
        numeroAiutanti: '',
        note: '',
        letteraConsegnaLavoroId: lcl.id,
      }
    : {
        id: FS.id,
        data: modalCreateFuoriServizio ? modalCreateFuoriServizio.date : FS.data,
        oraInizio: FS.oraInizio === null ? '' : FS.oraInizio,
        oraFine: FS.oraFine === null ? '' : FS.oraFine,
        allocazioneRisorse: FS.allocazioneRisorse,
        stato: FS.stato,
        motivazioneSaltato: FS.motivazioneSaltato,
        numeroPreposti: FS.numeroPreposti === null ? '' : FS.numeroPreposti,
        prepostiDesiderati: FS.prepostiDesiderati,
        cestelli: FS.cestelli,
        propedeutico: FS.propedeutico,
        numeroAiutanti: FS.numeroAiutanti === null ? '' : FS.numeroAiutanti,
        note: FS.note,
        letteraConsegnaLavoroId: FS.letteraConsegnaLavoro.id,
      }

  //========================
  // HOOKS FORM
  //========================
  const {
    register,
    watch,
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
  } = useForm<FuoriServizioForm>({
    defaultValues: fuoriServizioForm,
  })

  const { data: fuoriServizi } = useGetFuoriServizi(watch('data'))
  const { data: risorseDisponibiliSettimana } = useGetRisorseGiornata(watch('data'))

  //========================
  // LOCAL STATE
  //========================
  const statoFS = getStatoApprovazione(watch())

  //========================
  // EVENT HANDLER
  //========================
  const handleOnSubmit = (fuoriServizio: FuoriServizioForm) => {
    const fuoriServizioToSave: FuoriServizioUpdate = {
      ...fuoriServizio,
      numeroAiutanti: fuoriServizio.numeroAiutanti === '' ? null : +fuoriServizio.numeroAiutanti,
      numeroPreposti: fuoriServizio.numeroPreposti === '' ? null : +fuoriServizio.numeroPreposti,
      oraFine: isNaN(+fuoriServizio.oraFine) ? null : +fuoriServizio.oraFine,
      oraInizio: isNaN(+fuoriServizio.oraInizio) ? null : +fuoriServizio.oraInizio,
    }
    if (isInCreazione) {
      handleCloseModal()
      return createFuoriServizio.mutate({
        ...fuoriServizioToSave,
      })
    }
    handleCloseModal()
    return updateFuoriServizio.mutate(fuoriServizioToSave)
  }

  const risorseDisponibiliOggi = getDisponibiliPerGiorno(
    risorseDisponibiliSettimana,
    fuoriServizi!
  )?.find((elem) => elem.data === watch('data'))

  //============================
  // Calcolo max value Preposti
  const maxValuePreposti = useMemo(
    () =>
      getMaxValuePreposti({
        isNew: !!modalCreateFuoriServizio,
        risorseDisponibiliOggi,
        isPreposto: true,
        allocazioneRisorse: watch('allocazioneRisorse'),
        fuoriServizio: fuoriServizio || ({} as FuoriServizio),
        isDataDirty: dirtyFields.data,
        isAllocazioneRisorseDirty: dirtyFields.allocazioneRisorse,
      }),
    [
      dirtyFields.allocazioneRisorse,
      dirtyFields.data,
      fuoriServizio,
      modalCreateFuoriServizio,
      risorseDisponibiliOggi,
      watch,
    ]
  )
  //============================
  // Calcolo max value Aiutanti
  const maxValueAiutanti = useMemo(
    () =>
      getMaxValuePreposti({
        isNew: !!modalCreateFuoriServizio,
        risorseDisponibiliOggi,
        isPreposto: false,
        allocazioneRisorse: watch('allocazioneRisorse'),
        fuoriServizio: fuoriServizio || ({} as FuoriServizio),
        isDataDirty: dirtyFields.data,
        isAllocazioneRisorseDirty: dirtyFields.allocazioneRisorse,
      }),
    [
      dirtyFields.allocazioneRisorse,
      dirtyFields.data,
      fuoriServizio,
      modalCreateFuoriServizio,
      risorseDisponibiliOggi,
      watch,
    ]
  )

  //========================
  // EFFECT
  //========================

  useEffect(() => {
    setSaveButtonLabel(statoFS === Status.BOZZA ? 'Salva Bozza' : 'Salva')
  }, [setSaveButtonLabel, statoFS])

  useEffect(() => {
    // setHasCestelli(!!fuoriServizioForm?.cestelli.length)
    reset(fuoriServizioForm)
    // eslint-disable-next-line
  }, [fuoriServizio, lcl])

  //========================
  // DERIVED STATE
  //========================
  if (
    !user ||
    !cestelli ||
    !fuoriServizi ||
    !tecnici ||
    !risorseDisponibiliSettimana ||
    !risorseDisponibiliOggi
  ) {
    return (
      <Box w="full" h="full" display="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    )
  }

  const isCapoTecnico = isRoleCapoTecnico(user)

  const cestelliDisponibili = getCestelliDisponibiliGiorno(
    cestelli,
    fuoriServizi.filter((fuoriServizioGiorno) =>
      fuoriServizio
        ? fuoriServizioGiorno.data === fuoriServizio.data
        : fuoriServizioGiorno.data === modalCreateFuoriServizio?.date
    )
  )

  const statoPointerEvents = !isCapoTecnico ? { pointerEvents: 'none' } : {}

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom="4"
        paddingY="2"
        fontWeight="semibold"
        // borderRadius="md"
        borderBottom="1px"
        borderColor="gray.300"
      >
        {isInCreazione ? (
          <>
            {/* INTESTAZIONE COMUNE - NUMERO LCL */}
            <Box>
              <Box fontWeight="normal" as="span">
                Località:
              </Box>{' '}
              &nbsp;
              {lcl.comune}
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Box fontWeight="normal" as="span">
                Località:
              </Box>{' '}
              &nbsp;
              {fuoriServizio?.letteraConsegnaLavoro.comune}
            </Box>
          </>
        )}
        <Checkbox
          isReadOnly={
            !isInCreazione ||
            (!isCapoTecnico &&
              !(
                lcl?.tipologia === Tipologie.PROVVISORIO ||
                lcl?.tipologia === Tipologie.PROVVISORIO_GUASTO ||
                lcl?.tipologia === Tipologie.GUASTO ||
                dayjs(modalCreateFuoriServizio?.date).diff(dayjs().startOf('day'), 'days') >=
                  GIORNI_ANTICIPO_MIN
              ))
          }
          cursor={
            !isInCreazione ||
            (!isCapoTecnico &&
              !(
                lcl?.tipologia === Tipologie.PROVVISORIO ||
                lcl?.tipologia === Tipologie.PROVVISORIO_GUASTO ||
                lcl?.tipologia === Tipologie.GUASTO ||
                dayjs(modalCreateFuoriServizio?.date).diff(dayjs().startOf('day'), 'days') >=
                  GIORNI_ANTICIPO_MIN
              ))
              ? 'not-allowed'
              : ''
          }
          display="flex"
          alignItems="center"
          {...register('propedeutico')}
        >
          Propedeutico
        </Checkbox>
      </Flex>

      <form id="modalForm" onSubmit={handleSubmit(handleOnSubmit)}>
        <VStack alignItems="stretch" flex="1" spacing={30}>
          {(lcl?.tipologia === Tipologie.PROVVISORIO ||
            lcl?.tipologia === Tipologie.PROVVISORIO_GUASTO) && (
            <HStack justify="space-between">
              <VStack alignItems="flex-start">
                <InputAddon>Comune</InputAddon>
                <Input type="text" {...register('comune')} />
              </VStack>
              <VStack alignItems="flex-start">
                <Box>Tecnico</Box>
                <Controller
                  name="tecnicoId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={(e) => {
                        field.onChange(+e.target.value)
                      }}
                    >
                      <option value={''}>-- seleziona --</option>
                      {tecnici
                        .sort((a, b) => (a.cognome > b.cognome ? 1 : -1))
                        .map((tecnico) => (
                          <option key={tecnico.id} value={+tecnico.id}>
                            {tecnico.cognome} {tecnico.nome}
                          </option>
                        ))}
                    </Select>
                  )}
                />
              </VStack>
            </HStack>
          )}
          {/* SEZIONE DATA - ORA INIZIO___ORA FINE - ALLOCAZIONE RISORSE */}
          <HStack justify="space-between" alignItems="flex-start">
            {/* DATA */}
            <VStack alignItems="flex-start" flex="1">
              <Box>
                <InputAddon>Data</InputAddon>
                <Input
                  type="date"
                  {...register('data', {
                    required: true,
                    validate: {
                      dataMinoreDiCostanteGiorni: (value) => {
                        if (!dirtyFields.data) {
                          //se data non è stata modificata allora ritorna true
                          return true
                        }

                        const tipologia = isInCreazione
                          ? lcl.tipologia
                          : FS.letteraConsegnaLavoro.tipologia
                        return (
                          isCapoTecnico ||
                          dayjs(value).startOf('day').diff(dayjs().startOf('day'), 'days') >=
                            GIORNI_ANTICIPO_MIN ||
                          tipologia === Tipologie.PROVVISORIO ||
                          tipologia === Tipologie.PROVVISORIO_GUASTO ||
                          tipologia === Tipologie.GUASTO ||
                          (tipologia === Tipologie.FUORI_SERVIZIO && !!watch('propedeutico'))
                        )
                      },
                    },
                  })}
                />
              </Box>

              {errors.data && (
                <Box as="p" textColor="red.300" fontSize="xs">
                  Il fuori servizio che stai cercando di assegnare a questa data non è un
                  propedeutico.
                </Box>
              )}
            </VStack>
            {/* ORA INIZIO - ORA FINE */}
            <VStack alignItems="flex-start" flex="1">
              <HStack>
                <VStack alignItems="flex-start">
                  <Box>Ora Inizio</Box>
                  <Select {...register('oraInizio', { valueAsNumber: true })}>
                    <option value={''}>-- : --</option>
                    {ORE.map((ora) => {
                      return (
                        <option key={ora.value} value={ora.value}>
                          {ora.label}
                        </option>
                      )
                    })}
                  </Select>
                </VStack>

                <VStack alignItems="flex-start">
                  <Box>Ora Fine</Box>
                  <Select
                    {...register('oraFine', {
                      valueAsNumber: true,
                      validate: {
                        oraFineMaggioreDiOraInizio: (value) => {
                          return !value || !watch('oraInizio') || value > watch('oraInizio')
                        },
                      },
                    })}
                  >
                    <option value={''}>-- : --</option>
                    {ORE.map((ora) => {
                      return (
                        <option key={ora.value} value={ora.value}>
                          {ora.label}
                        </option>
                      )
                    })}
                  </Select>
                </VStack>
              </HStack>
              {errors.oraFine && (
                <Box as="p" textColor="red.300" fontSize="xs">
                  Ora inizio deve essere precedente a ora fine!
                </Box>
              )}
            </VStack>
            {/* ALLOCAZIONE RISORSE */}
            <VStack alignItems="flex-start" flex="1">
              <Box>Risorse allocate</Box>
              <Controller
                name="allocazioneRisorse"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(e) => {
                      setValue('cestelli', [])
                      field.onChange(e.target.value)
                    }}
                  >
                    <option value={AllocazioneRisorse.SELEZIONA}>-- seleziona --</option>
                    <option value={AllocazioneRisorse.MATTINO}>Mattino</option>
                    <option value={AllocazioneRisorse.POMERIGGIO}>Pomeriggio</option>
                    <option value={AllocazioneRisorse.GIORNATA}>Giornata Intera</option>
                  </Select>
                )}
              />
              {errors.allocazioneRisorse && (
                <Box as="p" textColor="red.300" fontSize="xs">
                  Questo campo è necessario per completare l'inserimento del fuori servizio!
                </Box>
              )}
            </VStack>
          </HStack>
          {/* SEZIONE STATO APPROVAZIONE E MOTIVAZIONE SALTATO */}
          <HStack
            alignItems="center"
            marginBottom="4"
            paddingY="2"
            paddingX="4"
            fontWeight="semibold"
            backgroundColor="gray.100"
            borderRadius="md"
            justify="space-between"
          >
            <HStack spacing="14">
              <Box>Stato</Box>
              <HStack justifyContent="space-between">
                {statoFS === Status.BOZZA ? (
                  <ViewOffIcon color="gray.400" fontSize="md" mt="1px" />
                ) : statoFS === Status.APPROVATO ? (
                  <Box color="cyan.500" fontSize="md" mt="1px">
                    <HiThumbUp />
                  </Box>
                ) : statoFS === Status.FATTO ? (
                  <CheckCircleIcon color="green.400" fontSize="md" />
                ) : statoFS === Status.SALTATO ? (
                  <WarningTwoIcon color="red.300" fontSize="md" />
                ) : (
                  <WarningIcon color="orange.300" fontSize="md" />
                )}

                {/* @ts-ignore */}
                <Select
                  {...statoPointerEvents}
                  {...register('stato', {
                    validate: {
                      fieldEmpty: () => {
                        //se si vuol settare lo stato APPROVATO allora si certifica che tutti i campi required siano inseriti
                        if (watch('stato') !== Status.DA_APPROVARE) {
                          return statoFS === Status.BOZZA ? false : true
                        }
                      },
                    },
                  })}
                  bg="white"
                  size="sm"
                  borderRadius="md"
                >
                  <option value={Status.DA_APPROVARE}>Da Approvare</option>
                  <option value={Status.APPROVATO}>Approvato</option>
                  <option value={Status.FATTO}>Eseguito</option>
                  <option value={Status.SALTATO}>Saltato</option>
                </Select>
              </HStack>
            </HStack>
            {watch('stato') === Status.SALTATO && (
              <HStack spacing="14">
                <Box>Motivazione</Box>
                <Select
                  bg="white"
                  size="sm"
                  borderRadius="md"
                  maxW="132px"
                  marginLeft="auto"
                  {...register('motivazioneSaltato')}
                >
                  <option value="">-- Non Indicata --</option>
                  <option value="Enel">Enel</option>
                  <option value="Meteo">Meteo</option>
                  <option value="Azienda">Azienda</option>
                  <option value="Tecnico">Tecnico</option>
                </Select>
              </HStack>
            )}
          </HStack>
          {errors.stato?.type === 'fieldEmpty' && (
            <Box as="p" textColor="red.300" fontSize="xs">
              Non sono completi i dati... non è possibile salvare un fuori servizio incompleto di
              dati se non in stato 'Da Approvare'!
            </Box>
          )}
          {/* SEZIONE AIUTANTI */}
          <VStack alignItems="flex-start" spacing={15}>
            {/* ================== */}
            {/* NUMERO PREPOSTI - PREPOSTI - CESTELLI - FLAG_PROPEDEUTICO */}
            {/* ================== */}
            <HStack w="full" spacing="8" alignItems="flex-start">
              {/* numero preposti */}
              <VStack spacing={4} alignItems="flex-start" w="100px">
                <Box
                  as="h3"
                  fontSize="small"
                  textTransform="uppercase"
                  color="gray.600"
                  fontWeight="medium"
                  letterSpacing="wider"
                  w="70px"
                >
                  Uomini
                </Box>
                <Box>
                  <Controller
                    name="numeroPreposti"
                    control={control}
                    rules={{
                      validate: {
                        lenghtArryMustBeLessThanThisNumber: (value) => {
                          const arrayPreposti = watch('prepostiDesiderati')
                          if (arrayPreposti.length === 0) return true
                          return arrayPreposti.length <= parseInt(value.toString())
                        },
                        numPrepostiMoreThanZero: () => {
                          return statoFS !== Status.BOZZA
                            ? watch('numeroPreposti') > 0
                              ? true
                              : false
                            : true
                        },
                        valueMinThanMax: (value) => {
                          return value === ''
                            ? true
                            : isCapoTecnico
                            ? +value <= 50
                            : +value <= maxValuePreposti
                        },
                      },
                    }}
                    render={({ field }) => (
                      <NumberInput
                        maxW="70px"
                        minW="70px"
                        min={0}
                        max={isCapoTecnico ? 50 : maxValuePreposti}
                        {...field}
                      >
                        <NumberInputField
                          backgroundColor={
                            watch('numeroPreposti') > maxValuePreposti ? 'red.100' : 'white'
                          }
                          borderColor={
                            watch('numeroPreposti') > maxValuePreposti ? 'red.300' : 'gray.200'
                          }
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  />
                  {watch('allocazioneRisorse') !== '' && (
                    <Box ml="1px" fontSize="xs" textColor="gray.400">
                      Ancora disp. {maxValuePreposti - (+watch('numeroPreposti') || 0)}
                    </Box>
                  )}
                </Box>
              </VStack>
              {/* preposti (nome - cognome) */}
              <VStack spacing={4} alignItems="flex-start" flex="1">
                <Box
                  flex="1"
                  as="h3"
                  fontSize="small"
                  textTransform="uppercase"
                  color="gray.600"
                  fontWeight="medium"
                  letterSpacing="wider"
                >
                  Preposti
                </Box>
                <Controller
                  name="prepostiDesiderati"
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      //@ts-ignore
                      isMulti
                      options={preposti}
                      getOptionValue={(preposto: Preposto) => preposto.id}
                      getOptionLabel={(preposto: Preposto) => preposto.nome}
                      placeholder="Seleziona preposti..."
                      closeMenuOnSelect={false}
                      {...field}
                    />
                  )}
                />
              </VStack>
              {/* select cestelli */}
              <VStack spacing={4} alignItems="flex-start" flex="1">
                <Box
                  flex="1"
                  as="h3"
                  fontSize="small"
                  textTransform="uppercase"
                  color="gray.600"
                  fontWeight="medium"
                  letterSpacing="wider"
                >
                  Cestelli
                </Box>
                <Controller
                  control={control}
                  name="cestelli"
                  render={({ field }) => (
                    <MultiSelect //@ts-ignore
                      isMulti
                      options={
                        cestelliDisponibili[getNomeAllocazioneRisorse(watch('allocazioneRisorse'))]
                      }
                      getOptionValue={(cestello: Cestello) => cestello.id}
                      getOptionLabel={(cestello: Cestello) => cestello.numero}
                      placeholder="Seleziona cestelli..."
                      closeMenuOnSelect={false}
                      {...field}
                    />
                  )}
                />
              </VStack>
            </HStack>
            {errors.numeroPreposti?.type === 'valueMinThanMax' && (
              <Box as="p" textColor="red.300" fontSize="xs">
                Numero preposti è oltre il massimo di questa giornata! Max:
                {isCapoTecnico ? 50 : maxValuePreposti}
              </Box>
            )}
            {errors.numeroPreposti?.type === 'lenghtArryMustBeLessThanThisNumber' && (
              <Box as="p" textColor="red.300" fontSize="xs">
                Il numero dei preposti selezionati non deve essere maggiore del numero preposti
                inserito
              </Box>
            )}
            {errors.numeroPreposti?.type === 'numPrepostiMoreThanZero' && (
              <Box as="p" textColor="red.300" fontSize="xs">
                Non è possibile inserire 0 preposti
              </Box>
            )}
            {/* ================== */}
            {/* NUMERO AIUTANTI */}
            {/* ================== */}
            <HStack w="full" justify="space-between">
              <HStack spacing="8">
                <Box w="100px">
                  <Controller
                    name="numeroAiutanti"
                    control={control}
                    rules={{
                      validate: {
                        valueMinThanMax: (value) => {
                          return value === ''
                            ? true
                            : isCapoTecnico
                            ? +value <= 50
                            : +value <= maxValueAiutanti
                        },
                      },
                    }}
                    render={({ field }) => (
                      <NumberInput
                        maxW="70px"
                        minW="70px"
                        min={0}
                        max={isCapoTecnico ? 50 : maxValueAiutanti}
                        {...field}
                      >
                        <NumberInputField
                          backgroundColor={
                            watch('numeroAiutanti') > maxValueAiutanti ? 'red.100' : 'white'
                          }
                          borderColor={
                            watch('numeroAiutanti') > maxValueAiutanti ? 'red.300' : 'gray.200'
                          }
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    )}
                  />
                  {watch('allocazioneRisorse') !== '' && (
                    <Box ml="1px" fontSize="xs" textColor="gray.400">
                      Ancora disp. {maxValueAiutanti - (+watch('numeroAiutanti') || 0)}
                    </Box>
                  )}
                </Box>
                <Box as="label" paddingLeft="1">
                  Aiutanti
                </Box>
              </HStack>
            </HStack>

            {errors.numeroAiutanti && (
              <Box as="p" textColor="red.300" fontSize="xs">
                Numero aiutanti è oltre il massimo di questa giornata! Max:
                {isCapoTecnico ? 50 : maxValueAiutanti}
              </Box>
            )}
          </VStack>

          {/* NOTE */}
          <Box my="8px">
            <Box marginBottom="2">Note</Box>
            <Textarea {...register('note')} />
          </Box>
        </VStack>
      </form>
    </Box>
  )
}

export default ModalForm