export type Tecnico = {
  id: number
  nome: string
  cognome: string
  email: string
}

export type LetteraConsegnaLavoro = {
  id: number
  comune: string
  descrizione: string
  numero: string
  provvisorio?: string
  ticket: string
  tecnico: Tecnico
  tipologia: Tipologie
}

// export type AllocazioneRisorse = 'MATTINO' | 'POMERIGGIO' | 'GIORNATA'
export enum AllocazioneRisorse {
  SELEZIONA = '',
  MATTINO = 'MATTINO',
  POMERIGGIO = 'POMERIGGIO',
  GIORNATA = 'GIORNATA',
}
export enum Tipologie {
  FUORI_SERVIZIO = 'FUORI_SERVIZIO',
  GUASTO = 'GUASTO',
  PROVVISORIO = 'PROVVISORIO',
  PROVVISORIO_GUASTO = 'PROVVISORIO_GUASTO',
}

export type FuoriServizio = {
  id: number
  data: string
  oraInizio: number | null
  oraFine: number | null
  allocazioneRisorse: AllocazioneRisorse
  numeroPreposti: number | null
  numeroAiutanti: number | null
  note: string
  stato: string
  letteraConsegnaLavoro: LetteraConsegnaLavoro
  prepostiDesiderati: Preposto[]
  cestelli: Cestello[]
  tecnico: Tecnico
  createdAt: string
  motivazioneSaltato?: string
  propedeutico: boolean
  tipologia: Tipologie
}

// export type FuoriServizioUpdate = Omit<FuoriServizio, 'letteraConsegnaLavoro' | 'tecnico'>

export type FuoriServizioForm = Omit<
  FuoriServizio,
  | 'tipologia'
  | 'tecnico'
  | 'letteraConsegnaLavoro'
  | 'createdAt'
  | 'id'
  | 'oraInizio'
  | 'oraFine'
  | 'numeroPreposti'
  | 'numeroAiutanti'
> & {
  comune?: string
  tecnicoId?: number
  id?: number
  letteraConsegnaLavoroId?: number
  oraInizio: number | string
  oraFine: number | string
  numeroPreposti: number | string
  numeroAiutanti: number | string
}

export type FuoriServizioUpdate = Omit<
  FuoriServizioForm,
  'oraFine' | 'oraInizio' | 'numeroAiutanti' | 'numeroPreposti'
> & {
  oraFine: number | null
  oraInizio: number | null
  numeroAiutanti: number | null
  numeroPreposti: number | null
}

export type Cestello = {
  id: number
  numero: string
}
export type Preposto = {
  id: number
  nome: string
}

export type RisorseGiornata = {
  data: string
  numeroPreposti: number
  numeroAiutanti: number
}

export type RisorseGiornataUpdate = RisorseGiornata

export type DisponibilitaGiorno = {
  data: string
  preposti: {
    numero: number
    disponibiliMattino: number
    disponibiliPomeriggio: number
    disponibiliGiornata: number
  }
  aiutanti: {
    numero: number
    disponibiliMattino: number
    disponibiliPomeriggio: number
    disponibiliGiornata: number
  }
}

export type CestelliDisponibili = {
  disponibiliMattino: Cestello[]
  disponibiliPomeriggio: Cestello[]
  disponibiliGiornata: Cestello[]
}

export enum Status {
  BOZZA = 'BOZZA',
  DA_APPROVARE = 'DA_APPROVARE',
  APPROVATO = 'APPROVATO',
  FATTO = 'FATTO',
  SALTATO = 'SALTATO',
}
