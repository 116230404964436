import * as React from 'react'
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react'
import ReactFocusLock from 'react-focus-lock'
import { useAuth0 } from '@auth0/auth0-react'

interface UserInfoProps {
  name?: string
  image?: string
  email?: string
}

export const UserInfo = (props: UserInfoProps) => {
  const { name, image, email } = props
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { logout } = useAuth0()
  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom"
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <Box
          as="a"
          href="#"
          p="3"
          display="block"
          transition="background 0.1s"
          rounded="xl"
          _hover={{ bg: 'whiteAlpha.200' }}
          whiteSpace="nowrap"
        >
          <Flex alignItems="center">
            <Avatar size="sm" name={name} src={image} />
            <Box lineHeight="1" marginLeft="2">
              <Text fontWeight="semibold">{name}</Text>
              <Text fontSize="xs" mt="1" color={mode('whiteAlpha.700', 'gray.400')}>
                {email}
              </Text>
            </Box>
          </Flex>
        </Box>
      </PopoverTrigger>
      <PopoverContent p={3} maxW="14rem" textColor="gray.800">
        <ReactFocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <Box>
            <HStack display="inline-flex">
              <Avatar size="sm" name={name} src={image} />
              <Box lineHeight="1">
                <Text fontWeight="semibold">{name}</Text>
                <Text fontSize="xs" mt="1">
                  {email}
                </Text>
              </Box>
            </HStack>
            <Box
              className="separator"
              borderTop="1px solid"
              borderColor="gray.300"
              mt="0.5rem"
              pb="0.5rem"
              mx="0.25rem"
            />
            <HStack
              _hover={{ background: 'var(--chakra-colors-gray-100)' }}
              rounded="md"
              p={1}
              cursor="pointer"
              onClick={() => logout()}
            >
              <Box>Esci da</Box> <Box as="span">@{name}</Box>
            </HStack>
          </Box>
        </ReactFocusLock>
      </PopoverContent>
    </Popover>
  )
}
