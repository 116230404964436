import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import { API_URL, AUDIENCE_URL } from '../../../config'

import { RisorseGiornata } from '../../../types'

const useGetRisorseGiornata = (from?: string) => {
  const to = dayjs(from).add(6, 'day').format('YYYY-MM-DD')
  const { getAccessTokenSilently } = useAuth0()
  return useQuery(['getRisorseGiornata', { from }], async (): Promise<RisorseGiornata[]> => {
    const accessToken = await getAccessTokenSilently({
      audience: AUDIENCE_URL,
    })
    const { data: giorni } = await axios.get(`${API_URL}/risorse-giornata?from=${from}&to=${to}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    return giorni
  })
}

export default useGetRisorseGiornata
