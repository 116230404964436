import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { Box, HStack, Spacer } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'

import ModalCreateSidebar from './SidebarModalCreate'

import ModalForm from './ModalForm'
import { Tipologie } from '../../types'
import renderTipologia from '../../lib/renderTipologia'

interface ModalCreateProps {
  modalCreateFuoriServizio: { date: string; tecnicoId: number }
  setModalCreateFuoriServizio: Dispatch<SetStateAction<{ date: string; tecnicoId: number }>>
}

const ModalCreate: React.FC<ModalCreateProps> = ({
  modalCreateFuoriServizio,
  setModalCreateFuoriServizio,
}) => {
  //========================
  // LOCAL STATE
  //========================
  const [selectedLcl, setSelectedLcl] = useState<{
    id: number
    comune: string
    numero: string
    tipologia: Tipologie | null
  }>({
    id: 0,
    comune: '',
    numero: '',
    tipologia: null,
  })
  const [saveButtonLabel, setSaveButtonLabel] = useState('Salva')

  //========================
  // HOOKS REACT QUERY
  //========================

  //========================
  // EVENT HANDLER
  //========================

  const handleCloseModal = () => {
    setSelectedLcl({ id: 0, comune: '', numero: '', tipologia: null })
    setModalCreateFuoriServizio({ date: '', tecnicoId: 0 })
  }
  //========================
  // DERIVED STATE
  //========================
  return (
    <Modal
      isOpen={modalCreateFuoriServizio.tecnicoId ? true : false}
      onClose={handleCloseModal}
      size="5xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <Box padding="1" bg="gray.200" rounded="md">
          <Box bg="white" rounded="sm">
            <ModalHeader bg="gray.200">
              <Box as="span" color="gray.900">
                Crea {selectedLcl.tipologia === null ? 'nuova' : 'nuovo'}{' '}
                {renderTipologia(selectedLcl.tipologia)}
              </Box>
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody px={2}>
              <HStack alignItems="stretch">
                <ModalCreateSidebar
                  setSelectedLcl={setSelectedLcl}
                  selectedLcl={selectedLcl}
                  modalCreateFuoriServizio={modalCreateFuoriServizio}
                />

                {selectedLcl.id !== 0 && (
                  <Box width="100%" paddingLeft="4">
                    <ModalForm
                      modalCreateFuoriServizio={modalCreateFuoriServizio}
                      setSaveButtonLabel={setSaveButtonLabel}
                      handleCloseModal={handleCloseModal}
                      lcl={selectedLcl}
                    />
                  </Box>
                )}
              </HStack>
            </ModalBody>
            <ModalFooter bg="gray.100" borderRadius="md">
              <HStack w="100%">
                <Spacer />
                <HStack>
                  <Button variant="ghost" mr={3} onClick={handleCloseModal}>
                    Annulla
                  </Button>
                  <Button type="submit" colorScheme="blue" variant="solid" form="modalForm">
                    {saveButtonLabel}
                  </Button>
                </HStack>
              </HStack>
            </ModalFooter>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default ModalCreate
