import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import TipologiaAttivita from '../../components/CalendarioFuoriServizio/TipologiaAttivita'
import { Tipologie } from '../../types'

interface IModalLegenda {
  modalLegendaOpen: boolean
  setModalLegendaOpen: (props: boolean) => void
}

const ModalLegenda: React.FC<IModalLegenda> = ({ modalLegendaOpen, setModalLegendaOpen }) => {
  return (
    <>
      <Modal
        isOpen={modalLegendaOpen}
        onClose={() => setModalLegendaOpen(false)}
        size="2xl"
        closeOnOverlayClick={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Legenda</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack py="4" alignItems="stretch" justifyContent="flex-start" spacing="12">
              <VStack w="full" alignItems="flex-start" spacing="4">
                <Box marginBottom="2">TIPOLOGIA:</Box>
                <HStack w="full" flex="1">
                  <TipologiaAttivita tipologia={Tipologie.FUORI_SERVIZIO} isPropedeutico={false} />

                  <Box as="span" px="1" fontWeight="medium" fontSize="xl">
                    =
                  </Box>
                  <Box as="span" fontWeight="medium" fontSize="xl">
                    Fuori Servizio
                  </Box>
                </HStack>
                <HStack w="full" flex="1">
                  <TipologiaAttivita tipologia={Tipologie.GUASTO} isPropedeutico={false} />
                  <Box as="span" px="1" fontWeight="medium" fontSize="xl">
                    =
                  </Box>
                  <Box as="span" fontWeight="medium" fontSize="xl">
                    Guasto
                  </Box>
                </HStack>
                <HStack w="full" flex="1">
                  <TipologiaAttivita tipologia={Tipologie.PROVVISORIO} isPropedeutico={false} />
                  <Box as="span" px="1" fontWeight="medium" fontSize="xl">
                    =
                  </Box>
                  <Box as="span" fontWeight="medium" fontSize="xl">
                    Provvisorio
                  </Box>
                </HStack>
                <HStack w="full" flex="1">
                  <TipologiaAttivita tipologia={Tipologie.PROVVISORIO_GUASTO} isPropedeutico={false} />
                  <Box as="span" px="1" fontWeight="medium" fontSize="xl">
                    =
                  </Box>
                  <Box as="span" fontWeight="medium" fontSize="xl">
                    Guasto provvisorio
                  </Box>
                </HStack>
                <HStack w="full" flex="1">
                <TipologiaAttivita tipologia={Tipologie.FUORI_SERVIZIO} isPropedeutico={true} />
                  <Box as="span" px="1" fontWeight="medium" fontSize="xl">
                    =
                  </Box>
                  <Box as="span" fontWeight="medium" fontSize="xl">
                    Propedeutico
                  </Box>
                </HStack>
              </VStack>
              <VStack w="full" alignItems="flex-start">
                <Box marginBottom="2">STATO:</Box>
                <HStack flex="1">
                  <Box w="28" h="14" border="2px solid" borderRadius="lg" borderColor="gray.400" />
                  <Box as="span" px="1" fontWeight="medium" fontSize="xl">
                    =
                  </Box>
                  <Box as="span" fontWeight="medium" fontSize="xl">
                    Bozza
                  </Box>
                </HStack>
                <HStack flex="1">
                  <Box
                    w="28"
                    h="14"
                    border="2px solid"
                    borderRadius="lg"
                    borderColor="orange.300"
                  />

                  <Box as="span" px="1" fontWeight="medium" fontSize="xl">
                    =
                  </Box>
                  <Box as="span" fontWeight="medium" fontSize="xl">
                    Da approvare
                  </Box>
                </HStack>
                <HStack flex="1">
                  <Box w="28" h="14" border="2px solid" borderRadius="lg" borderColor="cyan.500" />

                  <Box as="span" px="1" fontWeight="medium" fontSize="xl">
                    =
                  </Box>
                  <Box as="span" fontWeight="medium" fontSize="xl">
                    Approvato
                  </Box>
                </HStack>
                <HStack flex="1">
                  <Box w="28" h="14" border="2px solid" borderRadius="lg" borderColor="green.400" />

                  <Box as="span" px="1" fontWeight="medium" fontSize="xl">
                    =
                  </Box>
                  <Box as="span" fontWeight="medium" fontSize="xl">
                    Fatto
                  </Box>
                </HStack>
                <HStack flex="1">
                  <Box w="28" h="14" border="2px solid" borderRadius="lg" borderColor="red.300" />

                  <Box as="span" px="1" fontWeight="medium" fontSize="xl">
                    =
                  </Box>
                  <Box as="span" fontWeight="medium" fontSize="xl">
                    Saltato
                  </Box>
                </HStack>
              </VStack>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalLegenda
