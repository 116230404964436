import { Cestello, FuoriServizio, CestelliDisponibili, Status, AllocazioneRisorse } from '../types'
import { getStatoApprovazione } from './getStatoApprovazione'

export const getCestelliDisponibiliGiorno = (
  cestelli: Cestello[],
  fuoriServiziGiorno: FuoriServizio[]
): CestelliDisponibili => {
  // MATTINO
  const fuoriServizioConMattino = fuoriServiziGiorno.filter(
    (fuoriServizio) =>
      getStatoApprovazione(fuoriServizio) !== Status.BOZZA &&
      getStatoApprovazione(fuoriServizio) !== Status.SALTATO &&
      (fuoriServizio.allocazioneRisorse === AllocazioneRisorse.MATTINO ||
        fuoriServizio.allocazioneRisorse === AllocazioneRisorse.GIORNATA)
  )
  const cestelliOccupatiMattino = fuoriServizioConMattino.reduce((acc: Cestello[], fs) => {
    return [...acc, ...fs.cestelli]
  }, [])
  const cestelliLiberiMattino = new Set(
    cestelli.filter((cestello) => !cestelliOccupatiMattino.some((c) => c.id === cestello.id))
  )

  // POMERIGGIO
  const fuoriServizioConPomeriggio = fuoriServiziGiorno.filter((fuoriServizio) => {
    return (
      getStatoApprovazione(fuoriServizio) !== Status.BOZZA &&
      getStatoApprovazione(fuoriServizio) !== Status.SALTATO &&
      (fuoriServizio.allocazioneRisorse === AllocazioneRisorse.POMERIGGIO ||
        fuoriServizio.allocazioneRisorse === AllocazioneRisorse.GIORNATA)
    )
  })
  const cestelliOccupatiPomeriggio = fuoriServizioConPomeriggio.reduce((acc: Cestello[], fs) => {
    return [...acc, ...fs.cestelli]
  }, [])
  const cestelliLiberiPomeriggio = new Set(
    cestelli.filter((cestello) => !cestelliOccupatiPomeriggio.some((c) => c.id === cestello.id))
  )

  // GIORNATA
  const cestelliLiberiGiornata = new Set(
    Array.from(cestelliLiberiMattino).filter((c) => cestelliLiberiPomeriggio.has(c))
  )

  // RETURN
  return {
    disponibiliMattino: Array.from(cestelliLiberiMattino),
    disponibiliPomeriggio: Array.from(cestelliLiberiPomeriggio),
    disponibiliGiornata: Array.from(cestelliLiberiGiornata),
  }
}
