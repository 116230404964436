import {
  NumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/number-input'

interface QuantityInputProps {
  min?: number
  max?: number
  warningThreshold?: number
  value: number
  onChange: any
}

const QuantityInput: React.FC<QuantityInputProps> = ({
  min,
  max,
  warningThreshold,
  value,
  onChange,
  ...rest
}) => {
  return (
    <NumberInput value={value} onChange={onChange} min={min} max={max} {...rest}>
      <NumberInputField
        backgroundColor={warningThreshold && value > warningThreshold ? 'red.100' : 'white'}
        borderColor={warningThreshold && value > warningThreshold ? 'red.300' : 'gray.200'}
      />
      <NumberInputStepper>
        <NumberIncrementStepper backgroundColor="white" />
        <NumberDecrementStepper backgroundColor="white" />
      </NumberInputStepper>
    </NumberInput>
  )
}

export default QuantityInput
