import {
  RisorseGiornata,
  DisponibilitaGiorno,
  FuoriServizio,
  AllocazioneRisorse,
  Status,
} from '../types'
import { getStatoApprovazione } from './getStatoApprovazione'

export const getDisponibiliPerGiorno = (
  disponibilitaGiorni: RisorseGiornata[] | undefined,
  fuoriServizi?: FuoriServizio[]
): DisponibilitaGiorno[] | undefined => {
  if (disponibilitaGiorni && fuoriServizi) {
    return disponibilitaGiorni.map((giorno) => {
      const fuoriServiziGiornoMattino = fuoriServizi?.filter(
        (fuoriServizio) =>
          fuoriServizio.data === giorno.data &&
          getStatoApprovazione(fuoriServizio) !== Status.BOZZA &&
          getStatoApprovazione(fuoriServizio) !== Status.SALTATO &&
          (fuoriServizio.allocazioneRisorse === AllocazioneRisorse.MATTINO ||
            fuoriServizio.allocazioneRisorse === AllocazioneRisorse.GIORNATA)
      )

      const fuoriServiziGiornoPomeriggio = fuoriServizi.filter(
        (fuoriServizio) =>
          fuoriServizio.data === giorno.data &&
          getStatoApprovazione(fuoriServizio) !== Status.BOZZA &&
          getStatoApprovazione(fuoriServizio) !== Status.SALTATO &&
          (fuoriServizio.allocazioneRisorse === AllocazioneRisorse.POMERIGGIO ||
            fuoriServizio.allocazioneRisorse === AllocazioneRisorse.GIORNATA)
      )

      const impegnatiMattino = fuoriServiziGiornoMattino.reduce(
        (acc, fuoriServizio) => {
          return {
            preposti: acc.preposti + Number(fuoriServizio.numeroPreposti),
            aiutanti: acc.aiutanti + Number(fuoriServizio.numeroAiutanti),
          }
        },
        { preposti: 0, aiutanti: 0 }
      )

      const impegnatiPomeriggio = fuoriServiziGiornoPomeriggio.reduce(
        (acc, fuoriServizio) => {
          return {
            preposti: acc.preposti + Number(fuoriServizio.numeroPreposti),
            aiutanti: acc.aiutanti + Number(fuoriServizio.numeroAiutanti),
          }
        },
        { preposti: 0, aiutanti: 0 }
      )

      return {
        data: giorno.data,
        preposti: {
          numero: giorno.numeroPreposti,
          disponibiliMattino: giorno.numeroPreposti - impegnatiMattino.preposti,
          disponibiliPomeriggio: giorno.numeroPreposti - impegnatiPomeriggio.preposti,
          disponibiliGiornata:
            giorno.numeroPreposti -
            Math.max(impegnatiMattino.preposti, impegnatiPomeriggio.preposti),
        },
        aiutanti: {
          numero: giorno.numeroAiutanti,
          disponibiliMattino: giorno.numeroAiutanti - impegnatiMattino.aiutanti,
          disponibiliPomeriggio: giorno.numeroAiutanti - impegnatiPomeriggio.aiutanti,
          disponibiliGiornata:
            giorno.numeroAiutanti -
            Math.max(impegnatiMattino.aiutanti, impegnatiPomeriggio.aiutanti),
        },
      }
    })
  }
  return undefined
}

// Converte il nome AllocazioneRisorse (MATTINO, POMERIGGIO, ...)
// nella stringa chiave dell'oggetto ritornato dal getDisponibiliPerGiorno
export const getNomeAllocazioneRisorse = (
  allocazioneRisorse: AllocazioneRisorse
): 'disponibiliGiornata' | 'disponibiliMattino' | 'disponibiliPomeriggio' => {
  return allocazioneRisorse === AllocazioneRisorse.GIORNATA
    ? 'disponibiliGiornata'
    : allocazioneRisorse === AllocazioneRisorse.MATTINO
    ? 'disponibiliMattino'
    : 'disponibiliPomeriggio'
}
