import React, { Dispatch, SetStateAction } from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal'
import { Button } from '@chakra-ui/button'
import { Box } from '@chakra-ui/layout'

import useDeleteFuoriServizio from '../../hooks/api/mutations/useDeleteFuoriServizio'
import { FuoriServizio } from '../../types'

interface ModalDeleteProps {
  isDeleteModalOpen: boolean
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>
  fuoriServizio: FuoriServizio
}

const ModalDelete: React.FC<ModalDeleteProps> = ({
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  fuoriServizio,
}) => {
  const deleteFuoriServizio = useDeleteFuoriServizio()

  const handleCloseModalDelete = () => setIsDeleteModalOpen(false)
  const handleDeleteFuoriServizio = (id: number) => () => {
    deleteFuoriServizio.mutate(id)
    handleCloseModalDelete()
  }

  return (
    <Modal
      isOpen={isDeleteModalOpen}
      onClose={handleCloseModalDelete}
      size="lg"
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color="red.500">Elimina Fuori Servizio</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box as="h3" fontSize="md">
            Eliminare il fuori servizio{' '}
            <big>
              <b>{fuoriServizio.letteraConsegnaLavoro.numero}</b>
            </big>{' '}
            - {fuoriServizio.letteraConsegnaLavoro.comune}?
          </Box>
        </ModalBody>
        <ModalFooter borderRadius="md">
          <Button
            variant="solid"
            size="sm"
            colorScheme="red"
            onClick={handleDeleteFuoriServizio(fuoriServizio.id)}
          >
            Elimina
          </Button>
          <Button variant="ghost" size="sm" colorScheme="gray" onClick={handleCloseModalDelete}>
            Annulla
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalDelete
