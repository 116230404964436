import { useAuth0 } from '@auth0/auth0-react'
import { Box, Input, InputGroup, InputRightElement, Spinner, VStack } from '@chakra-ui/react'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { GIORNI_ANTICIPO_MIN } from '../../config'
import useGetLclByKeyword from '../../hooks/api/queries/useGetLclByKeyword'
import { isRoleCapoTecnico } from '../../lib/isRoleCapoTecnico'
import { Tipologie } from '../../types'

interface ModalCreateSidebarProps {
  setSelectedLcl: Dispatch<
    SetStateAction<{ id: number; comune: string; numero: string; tipologia: Tipologie | null }>
  >
  selectedLcl: { id: number; comune: string; numero: string; tipologia: Tipologie | null }
  modalCreateFuoriServizio: {
    date: string
    tecnicoId: number
  }
}

const ModalCreateSidebar: React.FC<ModalCreateSidebarProps> = ({
  setSelectedLcl,
  selectedLcl,
  modalCreateFuoriServizio,
}) => {
  const [keyword, setKeyword] = useState('')
  const [keywordUI, setKeywordUI] = useState('')

  const { user } = useAuth0()
  const isCapoTecnico = isRoleCapoTecnico(user)

  const {
    data: lcl,
    isFetching,
    refetch,
  } = useGetLclByKeyword({
    keyword,
    tecnico: modalCreateFuoriServizio.tecnicoId,
    enabled: keyword.length > 2,
    tipologie:
      isCapoTecnico ||
      dayjs(modalCreateFuoriServizio.date).diff(dayjs().startOf('day'), 'days') >=
        GIORNI_ANTICIPO_MIN
        ? [
            Tipologie.FUORI_SERVIZIO,
            Tipologie.GUASTO,
            Tipologie.PROVVISORIO,
            Tipologie.PROVVISORIO_GUASTO,
          ]
        : [
            Tipologie.FUORI_SERVIZIO,
            Tipologie.GUASTO,
            Tipologie.PROVVISORIO,
            Tipologie.PROVVISORIO_GUASTO,
          ],
  })
  const debouncedKeyword = _.debounce(setKeyword, 700)

  const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLcl({ id: 0, comune: '', numero: '', tipologia: null })
    setKeywordUI(event.target.value)
    debouncedKeyword(event.target.value)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    refetch()
  }

  return (
    <Box
      w={40}
      borderRight="1px solid var(--chakra-colors-gray-200)"
      pr="2"
      minH="350px"
      minW="200px"
    >
      <VStack as="form" onSubmit={handleSubmit}>
        <InputGroup>
          <Input
            autoFocus
            type="text"
            placeholder="LCL, guasto o provvisorio..."
            fontSize="xs"
            h={6}
            borderRadius="full"
            border="1px solid"
            borderColor="gray.400"
            value={keywordUI}
            onChange={handleChangeKeyword}
          />
          <InputRightElement pointerEvents="none" color="gray.300" h={6}>
            {isFetching ? <Spinner size="xs" /> : <BsSearch />}
          </InputRightElement>
        </InputGroup>
        <VStack
          alignItems="flex-start"
          w="100%"
          overflowY="auto"
          maxH="600px"
          spacing={1}
          css={{
            '&::-webkit-scrollbar': {
              width: '10px' /* width of the entire scrollbar */,
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent' /* color of the tracking area */,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'var(--chakra-colors-gray-400)' /* color of the scroll thumb */,
              borderRadius: '20px' /* roundness of the scroll thumb */,
              border: '3px solid white' /* creates padding around scroll thumb */,
            },
          }}
        >
          {!isFetching &&
            lcl?.map((lettera) => (
              <VStack
                key={lettera.id}
                w="100%"
                color={selectedLcl.id === lettera.id ? 'gray.600' : 'gray.400'}
                backgroundColor={selectedLcl.id === lettera.id ? 'gray.200' : 'white'}
                cursor="pointer"
                rounded="lg"
                onClick={() =>
                  setSelectedLcl({
                    id: lettera.id,
                    comune: lettera.comune,
                    numero: lettera.numero,
                    tipologia: lettera.tipologia,
                  })
                }
              >
                <VStack w="100%">
                  <Box
                    border="1px solid"
                    borderColor={selectedLcl.id === lettera.id ? 'gray.600' : 'gray.200'}
                    w="100%"
                    p={1}
                    rounded="lg"
                    _hover={{
                      textColor: 'var(--chakra-colors-gray-600)',
                      background: 'var(--chakra-colors-gray-200)',
                      borderColor: `${selectedLcl.id === lettera.id ? 'gray.600' : 'inherit'}`,
                    }}
                  >
                    <Box fontWeight="bold">{lettera.comune}</Box>
                    <Box color="gray.500" fontSize="xs">
                      {lettera.numero}
                    </Box>
                  </Box>
                </VStack>
              </VStack>
            ))}
        </VStack>
      </VStack>
    </Box>
  )
}

export default ModalCreateSidebar
